export default (app: { directive: (arg0: string, arg1: { mounted(el: any, binding: any): void; }) => void; }) => {
    app.directive('preReClick', {
      mounted(el, binding) {
        el.addEventListener('click', () => {
          if (!el.disabled) {
            el.disabled = true;
            setTimeout(() => {
              el.disabled = false;
            }, binding.value || 2000)
          }
        })
      }
    })
  }
  