<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/85" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="relativew-full w-[700px] transform overflow-hidden rounded-2xl bg-[#23232D] shadow-[0_10px_40px_#1d2021] p-6 text-left align-middle transition-all"
            >
              <div
                @click="isOpen = false"
                class="absolute top-4 right-4 closeHover"
              >
                <iconpark-icon
                  color="#ffffff"
                  size="20"
                  name="guanbiclose"
                ></iconpark-icon>
              </div>
              <div class="pt-10">
                <p class="text-[#ffffff] text-[18px] font-bold pb-2">
                  联系我们
                </p>

                <div
                  class="text-[rgba(255,255,255,0.6)] text-[14px] mt-3 h-[500px] overflow-auto pr-3"
                >
                  <!-- <p class="pb-6">QQ：3111305895</p> -->
                  <p class="pb-6">163：surpskins@163.com</p>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";

const isOpen = ref(false);

function closeModal() {
  isOpen.value = false;
}

//暴露state和play方法
defineExpose({
  isOpen,
});
</script>
