<template>
  <div
    class="fixed right-[10px] bottom-[150px] w-[40px] h-[40px] flex justify-center items-center bg-[#17171c] rounded-full"
  >
    <van-popover placement="left" v-model:show="showPopover">
      <img
        class="w-[100px]"
        src="@/assets/images/qrcode_for_gh_2ee69096fa90_430.jpg"
        alt=""
      />
      <template #reference>
        <iconpark-icon
          name="gongzhonghao"
          size="25"
          color="#feca7d"
          class="mt-1"
        ></iconpark-icon>
      </template>
    </van-popover>
  </div>
  <a
    class="fixed right-[10px] bottom-[100px] w-[40px] h-[40px] flex justify-center items-center bg-[#17171c] rounded-full"
    href="https://tb.53kf.com/code/client/c4ef83cda416cec45cf28e75bc05dd117/1"
    target="_blank"
  >
    <iconpark-icon size="20" name="customer" color="#feca7d"></iconpark-icon>
  </a>
</template>
<script lang="ts" setup>
import { ref } from "vue";
const showPopover = ref(false);
</script>
<style lang="scss" scoped></style>
