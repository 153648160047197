import axios from "./request";

export namespace Config {
  export interface ConfigData {
    // 是否开启调试模式
  }
}

// 获取全局配置
export const getConfig = () =>
  axios.get<Config.ConfigData>("/api/skins_client/siteConfig");


// 获取用户实时在线人数
export const getOnlineUsers = () => axios.get("/api/skins_client/siteConfig/countInfo");