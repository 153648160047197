import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router/index";
// 1. 引入你需要的组件
import { NoticeBar, Popover } from 'vant';
// 2. 引入组件样式
import 'vant/lib/index.css';
import "./permission";
import "./styles/index";
import "tailwindcss/tailwind.css"; 
import 'element-plus/dist/index.css'
import './styles/postcss.css';
import preReClick from './utils/preReClick';


const app = createApp(App);

app.use(router);
app.use(createPinia());
app.use(preReClick);
// 3. 注册你需要的组件
app.use(NoticeBar);
app.use(Popover);
app.mount("#app");

