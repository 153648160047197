<template>
  <div class="container p-0">
    <h3 class="mb-8 uppercase text-white text-center">充值</h3>
    <div class="relative z-30 flex min-h-0 flex-col items-center self-start">
      <!-- 支付方式 -->
      <ul class="grid min-h-0 w-full grid-cols-2 sm:grid-cols-3 gap-3">
        <li
          v-for="(item, index) in rechargeMethod"
          :key="'item' + index"
          @click="switchingPaymentMethods(index)"
          class="aspect-[5/4] h-[4rem] w-full cursor-pointer"
        >
          <a
            :class="
              paymentIndex === index
                ? 'border bg-navy-900 border-regal-yello'
                : 'border-transparent'
            "
            class="flex justify-center items-center trainstion relative h-full min-h-0 flex-col rounded-lg border bg-navy-700 bg-opacity-90 bg-clip-padding bg-no-repeat outline-none ring-gold duration-300 focus:outline-none hover:border-navy-300 hover:border-opacity-50 focus-visible:ring-1"
            ><div class="flex flex-row items-center justify-center gap-2">
              <iconpark-icon
                :name="item.icon"
                :color="item.color"
                size="110"
              ></iconpark-icon></div
          ></a>
        </li>
        <!-- <li class="aspect-[5/4] h-[4rem] w-full cursor-pointer">
          <a
            class="flex justify-center items-center trainstion relative h-full min-h-0 flex-col rounded-lg border bg-navy-700 bg-opacity-90 bg-clip-padding bg-no-repeat outline-none ring-gold duration-300 focus:outline-none hover:border-navy-300 hover:border-opacity-50 focus-visible:ring-1"
            ><div class="flex flex-row items-center justify-center gap-2">
              购买 CDK 联系客服
            </div></a
          >
        </li> -->
      </ul>
    </div>
    <div class="grid-stack grid h-full mt-4 md:mt-0">
      <div class="relative z-20" style="opacity: 1">
        <div class="flex flex-col gap-5 rounded-xl bg-navy-800 p-4 lg:mt-5">
          <!-- 支付金额 -->
          <div
            class="grid grid-cols-2 gap-5 sm:grid-cols-3 lg:gap-x-3 lg:gap-y-3 lg:grid-cols-4"
          >
            <div
              class="relative cursor-pointer"
              v-for="(item, index) in userStore.rechargePackage"
              @click="switchRechargeOptions(item)"
              :key="index"
            >
              <a
                class="relative flex rounded-lg ring-gold focus:outline-none focus-visible:ring-1 z-0 pay_icon items-center"
                ><div
                  :class="
                    selectedPackage === index
                      ? 'border-gold bg-gold-900 w-full'
                      : 'border-navy-700 bg-navy-700 pay_amount'
                  "
                  class="z-10 flex items-center justify-center rounded-lg border py-3 text-sm font-semibold text-white transition-all duration-200"
                >
                  <span class="font-semibold">{{ item }}</span>
                </div>
                <div
                  class="absolute right-0 flex w-[32px] h-[32px] items-center justify-center"
                >
                  <PaymentCurrency></PaymentCurrency></div
              ></a>
            </div>
          </div>
          <div
            class="rounded-lg lg:bg-navy-700 lg:bg-opacity-90 lg:p-6 lg:pt-5"
          >
            <!-- 充值金额换算 -->
            <div class="grid grid-cols-1 items-center gap-5 md:grid-cols-2">
              <div class="flex w-full flex-col">
                <div
                  class="mb-2 flex h-5 items-center text-10px font-semibold uppercase text-navy-200"
                >
                  <span>您的金额</span>
                </div>
                <label
                  class="flex h-13 overflow-hidden rounded-lg border border-navy-600 text-xs font-bold focus-within:border-navy-500"
                  ><div
                    class="flex h-full items-center justify-center bg-navy-600 px-3 text-center text-base font-semibold text-gold"
                  >
                    <div class="w-[32px] h-[32px]">
                      <PaymentCurrency></PaymentCurrency>
                    </div>
                  </div>
                  <input
                    :max="+userStore.maxRecharge"
                    :min="+userStore.minRecharge"
                    type="number"
                    class="flex-1 bg-transparent px-5 text-lg font-semibold text-white outline-none focus:outline-none lg:text-base"
                    v-model="rechargeAmount"
                /></label>
              </div>
              <div class="flex w-full flex-col">
                <span
                  class="mb-2 flex h-5 items-center text-10px font-semibold uppercase text-navy-200"
                  >您将收到<span
                    class="ml-2 inline-flex items-center whitespace-nowrap rounded bg-green-500 px-2 py-0.5 text-green-200"
                    >+{{ userStore.rechargeGive }}%</span
                  ></span
                >
                <div
                  class="flex h-13 w-full items-center rounded-lg bg-navy-700 px-2 lg:bg-navy-600"
                >
                  <div class="w-[32px] h-[32px]">
                    <PaymentCurrency></PaymentCurrency>
                  </div>
                  <span class="text-base font-semibold text-gold ml-1"
                    ><span class="relative"
                      ><span class="absolute right-0">{{
                        (
                          rechargeAmount * (userStore.rechargeGive / 100) +
                          +rechargeAmount
                        ).toFixed(2)
                      }}</span
                      ><span class="pointer-events-none opacity-0">{{
                        (
                          rechargeAmount * (userStore.rechargeGive / 100) +
                          +rechargeAmount
                        ).toFixed(2)
                      }}</span></span
                    ></span
                  >
                </div>
              </div>
            </div>
            <!-- 确认支付 -->
            <button
              v-preReClick
              @click="payment"
              class="button button-primary ml-auto h-13 text-sm text-gold-400 mt-5 w-full"
            >
              <span class=""
                >实际付款
                <span class="relative"
                  ><span class="absolute right-0">{{
                    (rechargeAmount * userStore.exchangeRate).toFixed(2)
                  }}</span
                  ><span class="pointer-events-none opacity-0">{{
                    (rechargeAmount * userStore.exchangeRate).toFixed(2)
                  }}</span></span
                ></span
              >
            </button>
          </div>
          <!-- 协议 -->
          <el-checkbox
            v-model="isCheckbox"
            style="height: 16px"
            class="py-4"
            size="large"
            :class="doYouWantToCheckTheAgreement ? 'shake-animation' : ''"
          >
            <div class="break-word leading-4">
              我已年满18周岁，完全具备民事行为能力。充值即表示同意
              <span
                @click.stop="openUserAgreement"
                class="text-regal-yello cursor-pointer"
              >
                用户协议
              </span>
              和
              <span
                @click.stop="openPrivacyPolicy"
                class="text-regal-yello cursor-pointer"
              >
                隐私政策
              </span>
            </div>
          </el-checkbox>
        </div>
      </div>
    </div>
  </div>
  <TransitionRoot appear :show="isPaymentQrCodeUrl" as="template">
    <Dialog as="div" @close="closeModel" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/85" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-[377px] transform overflow-hidden rounded-2xl bg-[#23232D] shadow-[0_10px_40px_#1d2021] p-6 text-left align-middle transition-all"
            >
              <div
                @click="closeModel"
                class="absolute top-4 right-4 closeHover"
              >
                <iconpark-icon
                  color="#ffffff"
                  size="20"
                  name="guanbiclose"
                ></iconpark-icon>
              </div>
              <div class="mt-3 m-auto text-center">
                <div class="text-white">
                  <span class="text-[13px]">付款金额：</span
                  ><span class="text-regal-yello font-medium text-[20px]">{{
                    (rechargeAmount * userStore.exchangeRate).toFixed(2)
                  }}</span>
                </div>
                <div
                  v-if="!paymentQrCodeUrl"
                  class="w-[168px] h-[168px] mt-4 bg-payError m-auto flex justify-center items-center flex-col"
                >
                  <iconpark-icon
                    name="loading"
                    size="30"
                    class="rotating"
                    color="#feca7d"
                  ></iconpark-icon>
                  <p class="text-regal-yello text-[13px]">加载中...</p>
                </div>
                <el-image
                  v-else
                  class="w-[168px] h-[168px] mt-4"
                  :src="paymentQrCodeUrl"
                >
                  <template #error>
                    <div
                      v-if="isError"
                      class="w-full h-full bg-payError flex items-center justify-center flex-col"
                    >
                      <iconpark-icon name="shibai1" size="20"></iconpark-icon>
                      <p class="text-[12px] pt-2">二维码失败，请重试！</p>
                      <el-button
                        type="primary"
                        class="mt-4"
                        :loading="loading"
                        @click="obtainPaymentQrCode"
                        >重试</el-button
                      >
                    </div>
                  </template>
                </el-image>
                <div class="text-left text-[11px] text-white mt-4">
                  <p>温馨提示:</p>
                  <p>1、请理性消费，避免过度充值。</p>
                  <p>2、未成年人禁止充值，如有发现，将永久封禁账号。</p>
                  <p>
                    3、充值购买的虚拟物品不可退货或换货，且不根据七天无理由退换货主张权利。
                  </p>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script lang="ts" setup>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
} from "@headlessui/vue";
import { ref, watch } from "vue";
import PaymentCurrency from "@/components/paymentCurrency/index.vue";
import { useUserStore } from "@/store/user.store";
import { appStateStore } from "@/store/app.store";
import { paymentWxQrCode, paymentWxStatus } from "@/api/pay";

let payInterval: number | undefined;
// 选择的充值项
const selectedPackage = ref<number | null>(0);
// 支付索引
const paymentIndex = ref<number>(0);
const isPaymentQrCodeUrl = ref(false);
const rechargeAmount = ref<number>(0);
const loading = ref(false);
const isError = ref(false);
const isCheckbox = ref(false);
const doYouWantToCheckTheAgreement = ref(false);
const appStore = appStateStore();
const userStore = useUserStore();
const paymentQrCodeUrl = ref("");
const rechargeMethod = ref([
  {
    id: 2,
    name: "微信",
    icon: "wachatPay",
    color: "#1AAD19",
    type: "wxpay",
  },
]);

rechargeAmount.value = userStore.rechargePackage[0];

// 切换充值金额方法
const switchRechargeOptions = (item: any) => {
  rechargeAmount.value = item;
};

// 监听充值金额变化
watch(rechargeAmount, (newAmount) => {
  const packageIndex = userStore.rechargePackage.findIndex(
    (pkg: number) => +pkg === +newAmount
  );
  if (packageIndex !== -1) {
    selectedPackage.value = packageIndex;
  } else {
    selectedPackage.value = null;
  }
});

// 切换充值方式
const switchingPaymentMethods = (index: number) => {
  paymentIndex.value = index;
};

const payment = () => {
  if (rechargeAmount.value > +userStore.maxRecharge) {
    ElMessage.error(`金额不得超过 - ${userStore.maxRecharge}。`);
    return;
  }
  if (rechargeAmount.value < +userStore.minRecharge) {
    ElMessage.error(`金额不得低于 - ${userStore.minRecharge}。`);
    return;
  }
  if (!isCheckbox.value) {
    doYouWantToCheckTheAgreement.value = true;
    setTimeout(() => {
      doYouWantToCheckTheAgreement.value = false;
    }, 820);
    return;
  }
  // 打开支付二维码弹窗（收款被封，换通道）
  isPaymentQrCodeUrl.value = true;
  obtainPaymentQrCode();
};

// 获取支付二维码
const obtainPaymentQrCode = async () => {
  loading.value = true;
  const requestData = await paymentWxQrCode({
    money: (rechargeAmount.value * userStore.exchangeRate).toFixed(2),
  });
  if (requestData) {
    const qrCodeBlob = requestData;
    const qrCodeUrl = URL.createObjectURL(qrCodeBlob as any);
    paymentQrCodeUrl.value = qrCodeUrl;
    loading.value = false;
    getPaymentStatus();
  } else {
    isError.value = true;
    loading.value = false;
  }
};

// 获取支付状态
const getPaymentStatus = async () => {
  payInterval = setInterval(async () => {
    // 获取支付状态的方法
    const requestData = await paymentWxStatus();
    if (requestData) {
      const { data } = requestData;
      // 0等待支付   1支付成功
      if (data === "1") {
        isPaymentQrCodeUrl.value = false;
        appStore.updateIsRechargeModel(false);
        clearInterval(payInterval);
        // 刷新用户信息
        userStore.updateUserMsg();
        ElMessage({
          message: "付款成功，请理性消费。",
          type: "success",
        });
      }
    } else {
      isPaymentQrCodeUrl.value = false;
      appStore.updateIsRechargeModel(false);
      clearInterval(payInterval);
    }
  }, 1000);
};

const closeModel = () => {
  clearInterval(payInterval);
  isPaymentQrCodeUrl.value = false;
};

const openUserAgreement = () => {
  // 打开用户协议的逻辑
  appStore.updateIsUserAgreementModel(true);
};
const openPrivacyPolicy = () => {
  // 打开隐私政策的逻辑
  appStore.updateIsPrivacyPolicyModel(true);
};
</script>

<style lang="scss" scoped>
.pay_icon {
  background: linear-gradient(270deg, rgb(65, 54, 40) 0%, rgb(20, 20, 22) 100%);
}

.pay_amount {
  width: calc(100% - 2rem);
}

.shake-animation {
  animation: shake-animation 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake-animation {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotating {
  animation: rotate 1s linear infinite;
}
</style>
