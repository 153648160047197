<template>
  <div class="overall-from mt-11 w-full">
    <el-form
      class="m-auto xl:max-w-[426px] max-w-[360px]"
      :rules="rules"
      ref="ruleFormRef"
      :model="formData"
      :hide-required-asterisk="true"
    >
      <el-form-item prop="mobile">
        <el-input
          class="w-[426px] h-[48px]"
          v-model="formData.mobile"
          placeholder="请输入您的注册手机号"
        />
      </el-form-item>
      <el-form-item prop="graphicVerificationCode">
        <div class="flex w-full">
          <el-input
            class="h-[48px] flex-1"
            v-model="formData.graphicVerificationCode"
            placeholder="请输入图形验证码"
          >
          </el-input>
          <div
            class="ml-4 w-[136px] h-[46px] cursor-pointer"
            @click="getObtainGraphicVerificationCode"
          >
            <img v-if="imageSrc" :src="imageSrc" alt="未获取到图片" />
          </div>
        </div>
      </el-form-item>
      <el-form-item prop="code">
        <div class="flex w-full">
          <el-input
            class="h-[48px] flex-1"
            v-model="formData.code"
            placeholder="请输入短信验证码"
          >
          </el-input>
          <el-button
            v-if="!disabled"
            v-preReClick
            class="getCode ml-4 w-[136px]"
            type="primary"
            @click="send(ruleFormRef)"
            ><span style="color: #000000">获取验证码</span></el-button
          >
          <el-button
            v-else
            type="primary"
            class="getCode ml-4 w-[136px]"
            disabled
            v-preReClick
            ><span style="color: rgba(0, 0, 0, 0.5)"
              >{{ count }} 秒后重新发送</span
            ></el-button
          >
        </div>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          class="w-[426px] h-[48px]"
          v-model="formData.password"
          show-password
          placeholder="请输入新密码"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          v-preReClick
          class="login mt-5"
          type="primary"
          @click="submitForm(ruleFormRef)"
          >重置密码</el-button
        >
      </el-form-item>
      <div class="text-sm sm:text-base text-body text-center">
        <button
          @click="login"
          type="button"
          v-preReClick
          class="text-sm sm:text-base text-heading underline font-bold hover:no-underline focus:outline-none"
        >
          登录
        </button>
      </div>
    </el-form>
  </div>
</template>
<script lang="ts" setup>
import { onMounted, reactive, ref } from "vue";
import { ElMessage, FormInstance, FormRules } from "element-plus";
import REGS from "@/utils/reg";
import { useRouter } from "vue-router";
import { useUserStore } from "@/store/user.store";
import {
  obtainGraphicVerificationCode,
  obtainVerificationCode,
} from "@/api/register";
import { resetPassword } from "@/api/login";

const ruleFormRef = ref<FormInstance>();
const canvasCode = ref("");
const disabled = ref(false);
const total = ref(60);
const count = ref(0);
const imageSrc = ref("");
const gvcKey = ref("");
const pcKey = ref("");

// 在组件上定义发出的事件
const emit = defineEmits({
  switchComponents: null, /// 返回搜索参数
  openLogin: null, // 关闭弹窗
});

const login = () => {
  emit("switchComponents", 1);
};

const validate_mobile = (rule: any, value: any, callback: any) => {
  if (!value) {
    return callback(new Error("请输入您的注册手机号"));
  } else if (!REGS.mobile.test(value)) {
    return callback(new Error("请输入正确的手机号"));
  } else {
    return callback();
  }
};

// 图形验证码
const validate_graphic_code = (rule: any, value: any, callback: any) => {
  if (!value) {
    return callback(new Error("请输入图形验证码"));
  } else {
    return callback();
  }
};

const validate_code = (rule: any, value: any, callback: any) => {
  if (!value) {
    return callback(new Error("请输入验证码"));
  } else if (!REGS.code.test(value)) {
    return callback(new Error("请输入正确的验证码"));
  } else {
    return callback();
  }
};

const rules = reactive<FormRules>({
  mobile: [{ validator: validate_mobile, trigger: "blur" }],
  graphicVerificationCode: [
    { validator: validate_graphic_code, trigger: "blur" },
  ],
  code: [{ validator: validate_code, trigger: "blur" }],
  password: [{ required: true, message: "请输入新密码", trigger: "blur" }],
});

const formData = reactive({
  mobile: ref(""),
  code: ref(""),
  graphicVerificationCode: ref(""),
  password: ref(""),
});

// 重置密码
const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate(async (valid, fields) => {
    if (valid) {
      const requestData = await resetPassword({
        phone: formData.mobile,
        password: formData.password,
        pcKey: pcKey.value,
        pcCode: formData.code,
      });
      if (requestData) {
        ElMessage({
          message: '密码重置成功，请登录。',
          type: "success",
        });
        // 重置密码的手机号给到登录
        emit('openLogin', formData.mobile)
      }
    }
  });
};

//获取子组件验证码的值
const getCode = (val: string) => {
  canvasCode.value = val;
};

// 计时器处理器
const timerHandler = () => {
  count.value = total.value;
  disabled.value = true;

  let timer = setInterval(() => {
    if (count.value > 1 && count.value <= total.value) {
      count.value--;
    } else {
      disabled.value = false;
      clearInterval(timer);
    }
  }, 1000);
};

// 发送验证码
const send = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  // 先验证图形验证码是否正确
  formEl.validateField(
    ["graphicVerificationCode", "mobile"],
    async (isValid: boolean, invalidFields) => {
      // 将回调函数声明为 async
      // 验证通过后启动计时器
      if (isValid) {
        // 发送验证码
        const requestData = await obtainVerificationCode({
          gvcKey: gvcKey.value,
          code: formData.graphicVerificationCode,
          phone: formData.mobile,
        });
        if (requestData) {
          timerHandler();
          const { data, code } = requestData;
          if (code === 200) {
            pcKey.value = data?.pcKey as string;
            ElMessage({
              message: "验证码已发送，请查收。",
              type: "success",
            });
          }
        }
      }
    }
  );
};

// 获取图形验证码
const getObtainGraphicVerificationCode = async () => {
  const requestData = await obtainGraphicVerificationCode();
  if (requestData) {
    const { data, code } = requestData;
    if (code === 200 && data) {
      gvcKey.value = data.gvcKey;
      const img = new Image();
      img.src = data.gvcCode;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        context?.drawImage(img, 0, 0);
        const imageURL = canvas.toDataURL("image/png");
        imageSrc.value = imageURL;
      };
    }
  }
};

onMounted(() => {
  getObtainGraphicVerificationCode();
});
</script>
<style lang="scss" scoped></style>
