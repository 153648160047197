<template>
  <div class="relative">
    <div
      v-if="tabIndex === 1 || tabIndex === 2 || tabIndex === 3"
      class="flex items-center justify-center"
    >
      <p
        :class="
          tabIndex === 1 || tabIndex === 3
            ? 'text-regal-yello border-b-regal-yello border-b-2'
            : 'border-b-2 border-b-[transparent]'
        "
        @click="handleTab(1)"
        class="tab text-ml font-bold tracking-wider m-5 uppercase"
      >
        登录
      </p>
      <p
        :class="
          tabIndex === 2
            ? 'text-regal-yello border-b-regal-yello border-b-2'
            : 'border-b-2 border-b-[transparent]'
        "
        @click="handleTab(2)"
        class="tab text-ml font-bold tracking-wider m-5 uppercase"
      >
        注册
      </p>
    </div>
    <div v-if="tabIndex === 4" class="flex items-center justify-center">
      <p
        @click="handleTab(1)"
        class="text-regal-yello border-b-regal-yello border-b-2 tab text-ml font-bold tracking-wider m-5 uppercase"
      >
        重置密码
      </p>
    </div>
    <!-- 登录 -->
    <Login
      v-if="tabIndex === 1"
      :mobile="mobile"
      @switchComponents="switchComponents"
      @closeModel="closeModel"
    ></Login>
    <!-- 验证码登录 -->
    <VerifiCodeLogin
      v-if="tabIndex === 3"
      @switchComponents="switchComponents"
      @closeModel="closeModel"
    ></VerifiCodeLogin>
    <!-- 重置密码 -->
    <ResetPassword
      v-if="tabIndex === 4"
      @switchComponents="switchComponents"
      @openLogin="openLogin"
    ></ResetPassword>
    <!-- 注册 -->
    <Register v-if="tabIndex === 2" @openLogin="openLogin"></Register>
  </div>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import Login from "./components/login.vue";
import Register from "./components/register.vue";
import VerifiCodeLogin from "./components/verifiCodeLogin.vue";
import ResetPassword from "./components/resetPassword.vue";

const tabIndex = ref(1);

const mobile = ref('')

const props = defineProps({
  buttonStatus: {
    type: String,
    default: "0",
  },
});

// 判断显示那个tab
tabIndex.value = Number(props.buttonStatus);

// 在组件上定义发出的事件
const emit = defineEmits({
  closeModel: null, /// 返回搜索参数
});

const closeModel = () => {
  emit("closeModel");
};

const handleTab = (index: number) => {
  tabIndex.value = index;
};

const switchComponents = (index: number) => {
  tabIndex.value = index;
};

const openLogin = (item: string) => {
  mobile.value = item
  switchComponents(1)
};

defineExpose({ handleTab });
</script>

<style lang="scss" scoped>
.tab {
  cursor: pointer;
  transition: filter 0.2s, color 0.2s;
  &:hover {
    filter: brightness(110%);
  }
}
</style>
