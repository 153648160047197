<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/85" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="relativew-full w-[700px] transform overflow-hidden rounded-2xl bg-[#23232D] shadow-[0_10px_40px_#1d2021] p-6 text-left align-middle transition-all"
            >
              <div
                @click="isOpen = false"
                class="absolute top-4 right-4 closeHover"
              >
                <iconpark-icon
                  color="#ffffff"
                  size="20"
                  name="guanbiclose"
                ></iconpark-icon>
              </div>
              <div class="pt-10">
                <p class="text-[#ffffff] text-[18px] font-bold pb-2">
                  关于我们
                </p>

                <div
                  class="text-[rgba(255,255,255,0.6)] text-[14px] mt-3 h-[500px] overflow-auto pr-3"
                >
                  <p class="pb-6">
                    SurpSkins主要提供Steam虚拟道具销售服务。我们致力于为用户提供安全、便捷的交易体验。您可以在我们的<a
                      class="underline cursor-pointer text-regal-yello"
                      href="https://store.steampowered.com/"
                      target="_blank"
                      >Steam官方商店</a
                    >上找到我们提供的CS2GO各类游戏饰品。
                  </p>
                  <p class="pb-6">
                    在SurpSkins平台上取回Steam虚拟道具非常简单。您只需在个人库存中选择所需的游戏饰品，然后点击“取回”按钮。由于Steam七天交易条款的限制，您需要等待其冷却期结束。冷却完毕后，点击“创建交易”按钮，您的游戏道具将被发送到您的Steam游戏库存中。
                  </p>
                  <p class="pb-6">
                    请注意，SurpSkins账户余额不支持提现，无法用于购买实物商品，也不可转赠他人。我们建议用户理性消费。所有虚拟道具交易均通过Steam机器人自动完成。如遇到任何人员向您推销或回收游戏币的情况，请立即联系我们进行反馈。
                  </p>
                  <p class="pb-6">
                    我们严格禁止任何传播违法、违规、低俗信息的行为。SurpSkins所有互动活动均为增加趣味性而设，请勿沉迷。同时，禁止利用互动活动进行赌博、欺诈、作弊或刷积分等违法违规行为。一经发现，我们将立即采取封禁措施。为了保障您的安全，请勿轻信任何诱导充值或私下交易的行为，以免造成财产损失。
                  </p>
                  <p class="pb-6">
                    1、账户安全：为了确保您的账户安全，建议您使用强密码，并定期更改密码。请勿与他人分享您的账户信息和密码，以防止账户被盗用。
                  </p>
                  <p class="pb-6">
                    2、用户隐私：我们致力于保护用户的隐私，未经用户同意，我们不会将用户的个人信息透露给任何第三方，除非法律法规另有规定或政府部门依法要求披露。
                  </p>
                  <p class="pb-6">
                    3、交易记录：所有交易记录将在您的账户历史中显示，您可以随时查看交易详情。若发现任何异常交易，请及时与我们联系，以便我们尽快处理。
                  </p>
                  <p class="pb-6">
                    4、技术支持：我们提供24/7客户服务，随时为您解答在使用平台过程中遇到的任何问题。您可以通过平台的客服中心或官方联系方式联系我们的支持团队。
                  </p>
                  <p class="pb-6">
                    5、服务更新：为了给用户提供更好的服务，我们会定期更新平台功能和服务条款。任何更新将在平台公告中提前通知，更新后的条款在通知规定的时间内生效，您继续使用平台服务即表示接受新的条款。
                  </p>
                  <p class="pb-6">
                    6、责任声明：对于因不可抗力因素或平台维护导致的服务中断，我们不承担任何责任，但会尽最大努力保障您的权益和交易安全。
                  </p>
                  <p class="pb-6">
                    7、用户行为规范：用户在使用SurpSkins平台时，应遵守国家法律法规及平台的各项规定，不得利用平台从事任何违法违规行为。对于违反规定的用户，我们有权采取相应措施，包括但不限于封禁账户、取消交易等。
                  </p>
                  <p class="pb-6">
                    8、虚拟道具管理：所有SurpSkins平台上的虚拟道具仅供个人娱乐使用，禁止用于商业用途或非法交易。用户应遵守Steam平台的使用条款及相关规定。
                  </p>
                  <p class="pb-6">
                    COPYRIGHT© 2024 WWW.SURPSKINS.COM ALL RIGHTS RESERVED.
                    @版权所有
                  </p>
                  <p class="pb-6">
                    请注意：以上条款旨在保障您的权益和平台的正常运营，感谢您的理解与配合。若有任何疑问，请随时联系我们的客服团队。
                  </p>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";

const isOpen = ref(false);

function closeModal() {
  isOpen.value = false;
}

//暴露state和play方法
defineExpose({
  isOpen,
});
</script>
