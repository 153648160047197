import axios from "./request";

export namespace Login {
  export interface LoginParams {
    phone: string;
    password?: string;
    code?: string;
    pcKey?: string;
    pcCode?: string;
    flag: string;
  }

  export interface ResetPasswordParams {
    phone: string;
    password?: string;
    pcKey?: string;
    pcCode?: string;
  }

  export interface Data {
    code: number;
    msg: string;
    data: string | null;
  }
}

// 登录
export const login = (params: Login.LoginParams) => {
  // 返回的数据格式可以和服务端约定
  return axios.post<Login.Data>("/api/skins_client/login/login", params);
};

// 退出登录
export const logout = () => {
  return axios.get<Login.Data>("/api/skins_client/login/logout");
};

// 重置密码
export const resetPassword = (params: Login.ResetPasswordParams) => {
  return axios.post<Login.Data>("/api/skins_client/login/reset", params);
}