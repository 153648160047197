<template>
  <div class="relative">
    <!-- 头部 -->
    <Header></Header>
    <!-- 实时掉落 -->
    <!-- <Live_live></Live_live> -->
    <van-notice-bar
      class="ml-[1.25rem] mr-[1.25rem] mt-5 bg-navy-800"
      color="#feca7d"
      @click="openNotification"
      left-icon="volume-o"
      :text="notificationText"
    />
    <!-- 路由 -->
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component
          :is="Component"
          :key="$route.path"
          v-if="$route.meta.keepAlive"
        />
      </keep-alive>
      <component
        :is="Component"
        :key="$route.path"
        v-if="!$route.meta.keepAlive"
      />
    </router-view>
    <Footer></Footer>
    <el-backtop :right="10" :bottom="50" :visibility-height="1300" />
    <Service></Service>
    <TransitionRoot appear :show="isNotification" as="template">
      <Dialog as="div" @close="isNotification = false" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/85"></div>
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center p-4 text-center"
          >
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel
                class="w-full max-w-md transform overflow-hidden rounded-2xl bg-[#23232D] shadow-[0_10px_40px_#1d2021] p-6 text-left align-middle transition-all"
              >
                <div
                  @click="isNotification = false"
                  class="absolute top-4 right-4 closeHover"
                >
                  <iconpark-icon
                    color="#ffffff"
                    size="20"
                    name="guanbiclose"
                  ></iconpark-icon>
                </div>
                <div class="text-center text-regal-yello">
                  新人有礼，注册即送专属好礼！
                </div>
                <div class="py-5 text-[#ffffff] text-[14px]">
                  {{ notificationText }}
                </div>
                <div class="py-5 text-[#ffffff] text-[14px]">
                  领取方式（无门槛）：
                </div>
                <div class="text-[#ffffff] text-[14px]">
                  注册 - 点击头像 - 福利乐园 - 领取
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup lang="ts">
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
} from "@headlessui/vue";
import { onMounted, ref, watch } from "vue";
import Header from "@/components/header/index.vue";
import Footer from "@/components/footer/index.vue";
import Service from "@/components/service/index.vue";
import Live_live from "@/components/live_live/index.vue";
import { useUserStore } from "./store";
import { useRoute, useRouter } from "vue-router";

const userStore = useUserStore();
const isNotification = ref(false);
// 通知文案
const notificationText = ref("");

watch(
  () => [userStore.announcement],
  () => {
    if (userStore.announcement.length > 0 && userStore.announcement !== "") {
      notificationText.value = userStore.announcement;
    }
  },
  {
    deep: true,
  }
);

// 请求用户信息
function reloadAllBasicMsg() {
  // 更新用户信息
  userStore.updateUserMsg();
  // 更新用户库存信息
  userStore.getUserInventoryValue();
  // 更新全局配置
  userStore.updateGlobalConfig();
  // 获取在线人数
  userStore.getTheGlobalOnlinePopulation();
  setInterval(() => {
    userStore.getTheGlobalOnlinePopulation();
  }, 10000);
}

const openNotification = () => {
  isNotification.value = true;
};

onMounted(() => {
  if (!localStorage.getItem("notificationTextStatus")) {
    localStorage.setItem("notificationTextStatus", "true");
    isNotification.value = true;
  }
  reloadAllBasicMsg();
});

const route = useRoute();
const router = useRouter();
watch(
  () => route.query,
  (newQuery) => {
    if (
      newQuery.money &&
      newQuery.name &&
      newQuery.out_trade_no &&
      newQuery.pid &&
      newQuery.trade_no &&
      newQuery.trade_status &&
      newQuery.type &&
      newQuery.sign
    ) {
      ElMessage({
        message: "付款成功，请理性消费。",
        type: "success",
      });
      // 保持当前路径，但清除查询参数
      router.replace({
        path: route.path, // 保留当前路径
        query: {}, // 清空查询参数
      });
    }
  },
  { immediate: true } // 确保在组件挂载时立即检查一次
);
</script>

<style scoped></style>
