/// 获取当前url上携带的参数
/// param 参数对应的key
export const getQueryString = (param: String) => {
  //param为要获取的参数名 注:获取不到是为null
  const currentUrl = window.location.href; //获取当前链接
  let arr = currentUrl.split("?"); //分割域名和参数界限
  if (arr.length > 1) {
    arr = arr[1].split("&"); //分割参数
    for (let i = 0; i < arr.length; i++) {
      const tem = arr[i].split("="); //分割参数名和参数内容
      if (tem[0] === param) {
        return tem[1];
      }
    }
    return null;
  }
  return null;
};

/// 获取当日时间的 24点
export const getEndOfDay = () => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  currentDate.setDate(currentDate.getDate() + 1);
  currentDate.setTime(currentDate.getTime() - 1);

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const hour = String(currentDate.getHours()).padStart(2, "0");
  const minute = String(currentDate.getMinutes()).padStart(2, "0");
  const second = String(currentDate.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
};

export const getRandomTimestamp = () => {
  const start = new Date(2000, 0, 1).getTime();
  const end = new Date().getTime();
  return new Date(start + Math.random() * (end - start)).getTime();
};

export const uniqueId = () => {
  return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
};
