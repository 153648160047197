import { InventoryPagePath, ExtractRecordPagePath, FreeCoinPagePath, PromotionPagePath, RechargeRecordPagePath } from "@/router/modules/user";
import { ref } from "vue";

export const helpMenu = ref([
  {
    name: "我的库存",
    path: InventoryPagePath,
    iconparkIconName: 'cangkukucun'
  },
  {
    name: "提取记录",
    path: ExtractRecordPagePath,
    iconparkIconName: 'weicantingicon7'
  },
  {
    name: "余额变动",
    path: RechargeRecordPagePath,
    iconparkIconName: 'chongzhijilu'
  },
  {
    name: "福利乐园",
    path: FreeCoinPagePath,
    iconparkIconName: 'mianfeilingqu'
  },
  {
    name: "推广中心",
    path: PromotionPagePath,
    iconparkIconName: 'jiamengguanli1'
  },
]);