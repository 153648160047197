// 用户中心
const userCenterPage = () => import("@/views/user/index.vue");
// 库存
const inventoryPage = () => import("@/views/user/inventory/index.vue");
// 免费金币
const freeCoinPage = () => import("@/views/user/free-coin/index.vue");
// 设置
const settingPage = () => import("@/views/user/setting/index.vue")
// 推广合作
const promotionPage = () => import("@/views/user/promotion/index.vue")
// 充值记录
const rechargecordeRPage = () => import("@/views/user/recharge-record/index.vue")
// 提取纪录
const extractRecordPage = () => import("@/views/user/extract-record/index.vue")


// 用户中心
export const UserCenterPagePath = "/user";
export const UserCenterName = "UserCenterPage";

// 库存
export const InventoryPagePath = "/user/inventory";
export const InventoryName = "InventoryPage";

// 提取纪录
export const ExtractRecordPagePath = "/user/extract-record";
export const ExtractRecordName = "ExtractRecordPage";

// 免费金币
export const FreeCoinPagePath = "/user/free-coin";
export const FreeCoinName = "FreeCoinPage";

// 充值记录
export const RechargeRecordPagePath = "/user/recharge-record";
export const RechargeRecordName = "RechargeRecordPage";

// 设置
export const SettingPagePath = "/user/setting";
export const SettingName = "SettingPage";

// 推广合作
export const PromotionPagePath = "/user/promotion";
export const PromotionName = "PromotionPage";

const routes = [
  {
    path: UserCenterPagePath,
    name: UserCenterName,
    component: userCenterPage,
    redirect: { name: InventoryPagePath },
    children: [
      // 库存
      {
        path: InventoryPagePath,
        name: InventoryName,
        meta: {
          need_login: true
        },
        component: inventoryPage,
      },

      // 提取纪录
      {
        path: ExtractRecordPagePath,
        name: ExtractRecordName,
        meta: {
          need_login: true
        },
        component: extractRecordPage,
      },
      // 免费金币
      {
        path: FreeCoinPagePath,
        name: FreeCoinName,
        meta: {
          need_login: true
        },
        component: freeCoinPage,
      },
      // 设置
      {
        path: SettingPagePath,
        name: SettingName,
        meta: {
          need_login: true
        },
        component: settingPage,
      },
      // 推广合作
      {
        path: PromotionPagePath,
        name: PromotionName,
        meta: {
          need_login: true
        },
        component: promotionPage,
      },

      // 充值记录
      {
        path: RechargeRecordPagePath,
        name: RechargeRecordName,
        meta: {
          need_login: true
        },
        component: rechargecordeRPage,
      },
    ],
  },
];

export default routes;
