<template>
  <footer class="bg-navy-750" style="">
    <div
      class="container flex justify-around gap-2 py-6 sm:gap-10 lg:py-9 xxl:justify-between"
    >
      <div class="flex min-w-fit items-center gap-x-2.5 sm:gap-x-3 lg:gap-x-5">
        <svg
          class="mt-[0.2rem] h-5 w-5 text-gold sm:h-7 sm:w-7 lg:h-10 lg:w-10"
        >
          <use xlink:href="../../assets/icons.svg?44#online"></use>
        </svg>
        <div>
          <span
            class="block text-base pb-1 font-semibold tabular-nums leading-none text-gold sm:text-xl lg:text-2xl"
            >{{ userStore?.numberOfOnlineUsers }}</span
          ><span
            class="block text-sm font-semibold uppercase leading-none text-navy-100 md:text-xs"
            >在线</span
          >
        </div>
      </div>
      <div class="flex min-w-fit items-center gap-x-2.5 sm:gap-x-3 lg:gap-x-5">
        <svg
          class="mt-[0.2rem] h-5 w-5 text-gold sm:h-7 sm:w-7 lg:h-10 lg:w-10"
        >
          <use xlink:href="../../assets/icons.svg?44#user4"></use>
        </svg>
        <div>
          <span
            class="block text-base pb-1 font-semibold tabular-nums leading-none text-gold sm:text-xl lg:text-2xl"
            >{{ userStore?.totalNumberOfUsers }}</span
          ><span
            class="block text-sm font-semibold uppercase leading-none text-navy-100 md:text-xs"
            >用户</span
          >
        </div>
      </div>
      <div class="flex min-w-fit items-center gap-x-2.5 sm:gap-x-3 lg:gap-x-5">
        <svg
          class="mt-[0.2rem] h-5 w-5 text-gold sm:h-7 sm:w-7 lg:h-10 lg:w-10"
        >
          <use xlink:href="../../assets/icons.svg?44#key2"></use>
        </svg>
        <div>
          <span
            class="block text-base pb-1 font-semibold tabular-nums leading-none text-gold sm:text-xl lg:text-2xl"
            >{{ userStore?.totalNumberOfOpenedChests }}</span
          ><span
            class="block text-sm font-semibold uppercase leading-none text-navy-100 md:text-xs"
            >已开启的宝箱</span
          >
        </div>
      </div>
    </div>
    <div class="bg-navy-600">
      <div class="container text-centent text-10px font-normal lg:py-10">
        <div class="grid grid-cols-4 gap-4 mb-10 pt-10 lg:pt-0 lg:grid-cols-6">
          <div class="text-[12px] text-center hover" @click="openUserAgreement">
            用户协议
          </div>
          <div class="text-[12px] text-center hover" @click="openPrivacyPolicy">
            隐私政策
          </div>
          <div class="text-[12px] text-center hover" @click="openCommonProblem">
            常见问题
          </div>
          <div class="text-[12px] text-center hover" @click="openAboutUs">
            关于我们
          </div>
          <div
            class="text-[12px] text-center hover"
            @click="openAntiMoneyLaunderingAgreement"
          >
            反洗钱协议
          </div>
          <!-- <div class="text-[12px] text-center hover" @click="openContactUs"> -->
          <div class="text-[12px] text-center hover">
            <a
              href="https://tb.53kf.com/code/client/c4ef83cda416cec45cf28e75bc05dd117/1"
              target="_blank"
              >联系我们</a
            >
          </div>
          <!-- <div class="text-[12px] text-center hover">可证明公平</div> -->
        </div>
        <p class="text-center lg:uppercase pb-1 text-[12px]">
          <a
            class="hover"
            href="https://beian.miit.gov.cn/#/Integrated/index"
            target="_blank"
            >© 备案号：晋ICP备2024043004号</a
          >
        </p>
        <p class="text-center lg:uppercase text-[12px] pb-10 lg:pb-0">
          Copyright© 2024 www.surpskins.com All rights reserved.
        </p>
      </div>
    </div>
    <!-- 用户协议 -->
    <UserAgreement></UserAgreement>
    <!-- 隐私政策 -->
    <PrivacyPolicy></PrivacyPolicy>
    <!-- 常见问题 -->
    <CommonProblem ref="commonProblem"></CommonProblem>
    <!-- 关于我们 -->
    <AboutUs ref="aboutUs"></AboutUs>
    <!-- 反洗钱协议 -->
    <AntiMoneyLaunderingAgreement
      ref="antiMoneyLaunderingAgreement"
    ></AntiMoneyLaunderingAgreement>
    <!-- 联系我们 -->
    <ContactUs ref="contactUs"></ContactUs>
  </footer>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import UserAgreement from "./userAgreement.vue";
import PrivacyPolicy from "./privacyPolicy.vue";
import CommonProblem from "./commonProblem.vue";
import AboutUs from "./aboutUs.vue";
import AntiMoneyLaunderingAgreement from "./antiMoneyLaunderingAgreement.vue";
import ContactUs from "./contactUs.vue";
import { appStateStore } from "@/store/app.store";
import { useUserStore } from "@/store/user.store";

const appStore = appStateStore();
const userStore = useUserStore();

const openUserAgreement = () => {
  appStore.updateIsUserAgreementModel(true);
};

const openPrivacyPolicy = () => {
  appStore.updateIsPrivacyPolicyModel(true);
};

// 常见问题
const commonProblem = ref(null);

const openCommonProblem = () => {
  if (commonProblem.value) {
    commonProblem.value.isOpen = true;
  }
};

// 关于我们
const aboutUs = ref(null);

const openAboutUs = () => {
  if (aboutUs.value) {
    aboutUs.value.isOpen = true;
  }
};

// 反洗钱协议
const antiMoneyLaunderingAgreement = ref(null);

const openAntiMoneyLaunderingAgreement = () => {
  if (antiMoneyLaunderingAgreement.value) {
    antiMoneyLaunderingAgreement.value.isOpen = true;
  }
};

// 联系我们
const contactUs = ref(null);

const openContactUs = () => {
  if (contactUs.value) {
    contactUs.value.isOpen = true;
  }
};
</script>
