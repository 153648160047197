import Cookies from "js-cookie";

/**
 * @description 保存cookie(设置路径为/，domain下全部生效)
 * @param {Object} json 需要存储cookie的对象
 * @param {Number} days 默认存储多少天
 */
function setCookie(json: any, days: number) {
  for (const key in json) {
    Cookies.set(key, json[key], { expires: days });
  }
}

/**
 * @description 获取cookie
 * @param {String} name 需要获取cookie的key
 */
function getCookie(name: string) {
  return Cookies.get(name);
}

/**
 * @description 删除cookie
 * @param {String} name 需要删除cookie的key
 */
function clearCookie(name: string, options?: Cookies.CookieAttributes) {
  Cookies.remove(name, options);
}

export { setCookie, getCookie, clearCookie };
