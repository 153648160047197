import axios from "./request";

export namespace CDK {
  export interface CdkParams {
    cdkCode: string;
  }

  export interface Data {
    code: number;
    msg: string;
    data: string | null;
  }
}

// 兑换cdk
export const redeemCdk = (params: CDK.CdkParams) => {
  return axios.post<CDK.Data>("/api/skins_client/cdk", params);
};
