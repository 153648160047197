import { URL } from "./env";
import { useSocketStore } from "./store/socket.store";
import { uniqueId } from "./utils/util";

export let socket: WebSocket;
let attemptCount = 0;
const maxAttempts = 5;
let requestOnTimeWs: number | undefined;

function createWebSocket(ipValue: string) {
  socket = new WebSocket(URL + `/${ipValue}`);

  // 连接建立时触发
  socket.onopen = function () {
    console.log("WebSocket 连接成功! " + new Date().toLocaleString());
    attemptCount = 0; // 重置尝试计数

    // 全部的
    // socket.send(`{"type": "allDrop", "userId": "${ipValue}"}`);
    // 最佳的
    // socket.send(`{"type": "bestAllItems", "userId": "${ipValue}"}`);

    // startRequest(ipValue);

    // 在线人数
    // socket.send('{"type": "countInfo"}');
    // setInterval(() => {
    //   socket.send('{"type": "countInfo"}');
    // }, 4000);
  };

  socket.onmessage = function (event) {
    try {
      const data = JSON.parse(event.data);

      if (!data.type) {
        return;
      }

      switch (data.type) {
        case "countInfo":
          realTimeOnlineAttendance(data.content);
          break;
        case "allItems":
          // 普通全部
          allRealTimeDrop(data.content, ipValue);
          break;
        case "bestAllItems":
          // 最佳全部
          bestDrop(data.content);
          break;
        case "latestDrop":
          // 全部实时掉落
          latestDropItems(data.content);
          break;
        case "bestDrop":
          // 最佳实时掉落
          bestDropItems(data.content);
          break;
        default:
          console.error("Unknown message type:", data.type);
      }
    } catch (error) {
      console.error("Failed to parse message:", event.data);
    }
  };

  // 通信发生错误时触发
  socket.onerror = function (event) {
    console.error("WebSocket error occurred:", event);
    reconnect();
  };

  // 连接关闭时触发
  socket.onclose = function (e) {
    console.log(
      "WebSocket connection closed: " +
        e.code +
        " " +
        e.reason +
        " " +
        e.wasClean
    );
    console.log(e);
    console.log("WebSocket is closed");
    reconnect();
  };
}

// 重新连接函数
function reconnect() {
  if (attemptCount < maxAttempts) {
    console.log("Attempting to reconnect... Attempt " + (attemptCount + 1));
    attemptCount++;
    setTimeout(() => {
      createWebSocket(uniqueId().toString());
    }, 5000); // 5秒后尝试重新连接
  } else {
    console.log(
      "Max reconnect attempts reached. No more attempts will be made."
    );
  }
}

// 初始化 WebSocket 连接
createWebSocket(uniqueId().toString());

// 实时在线人数
const realTimeOnlineAttendance = (item: any) => {
  // const socketStore = useSocketStore();
  // socketStore.appendRealTimeOnline(item);
};

// 全部掉落
const allRealTimeDrop = (item: any, ipValue: string) => {
  const socketStore = useSocketStore();
  socketStore.appendAllRealTimeDrop(item);
  socketStore.appendLinkIdentification(ipValue);
};

// 最佳掉落
const bestDrop = (item: any) => {
  const socketStore = useSocketStore();
  socketStore.appendBestRealTimeDrop(item);
};

// 全部实时掉落
const latestDropItems = (item: any) => {
  const socketStore = useSocketStore();
  socketStore.appendLatestDropItems(item);
};

// 最佳实时掉落
const bestDropItems = (item: any) => {
  const socketStore = useSocketStore();
  socketStore.appendBestDropItems(item);
};

// 开始轮训请求
// export const startRequest = (ipValue: string) => {
//   requestOnTimeWs = setInterval(() => {
//     // 全部的
//     socket.send(`{"type": "allDrop", "userId": "${ipValue}"}`);
//     // 最佳的
//     socket.send(`{"type": "bestAllItems", "userId": "${ipValue}"}`);
//   }, 120000);
// };

// 清除轮训
export const clearRequest = () => clearInterval(requestOnTimeWs);
