import axios from "../request";

export namespace TaskModel {
  export interface PersonalTasksInfo {
    id: number;
    taskRecordId: string;
    userId: string;
    taskId: string;
    type: string;
    taskState: string;
    taskDeadline: string;
    status: string;
    taskApplicableStatus: string;
    claimStatus: string;
    createTime: string;
    updateTime: string;
  }

  export interface WelfareTasksInfo {
    id: number;
    taskId: string;
    majorHeading: string;
    secondaryTitle: string;
    promotionalImage: string;
    startTime: string;
    endTime: string;
    unlockRule: string;
    rewardRule: string;
    taskType: string;
    taskState: string;
    orderNo: number;
    taskApplicableStatus: string;
    createName: string;
    createTime: string;
    updateTime: string;
  }

  export interface WelfareInnerVosItem {
    personalTasksInfo: PersonalTasksInfo;
    welfareTasksInfo: WelfareTasksInfo;
  }

  export interface TaskDataModel {
    welfareInnerVos: WelfareInnerVosItem[];
    consumptionMap: {
      consumptionDate: string;
      consumptionMonth: string;
      consumptionYear: string;
    };
    rechargeMap: {
      rechargeDate: string;
      rechargeMonth: string;
      rechargeYear: string;
    };
  }
}

// 保存用户任务
export const saveTask = () => {
  // 返回的数据格式可以和服务端约定
  return axios.get<any>("/api/skins_client/welfare/saveTask");
};

// 获取任务列表
export const getTaskListApi = () => {
  return axios.get<TaskModel.TaskDataModel>("/api/skins_client/welfare");
};

// 更新任务状态
export const updateTaskStatusApi = () => {
  return axios.get<any>("/api/skins_client/welfare/queryTaskCompleted");
}

// 领取奖励
export const getRewardApi = (taskRecordId: string) => {
  return axios.get<any>("/api/skins_client/welfare/obtainRewards?taskRecordId=" + taskRecordId);
}