const youdaoPage = () => import("@/views/youdao/index.vue");

export const YoudaoPagePath = "/youdao";
export const YoudaoPageName = "YoudaoPage";

const routes = [
  {
    path: YoudaoPagePath,
    name: YoudaoPageName,
    component: youdaoPage,
  }
];

export default routes;