import axios from "./request";

export namespace Register {

  export interface Data {
    gvcCode: string;
    gvcKey: string;
  }

  export interface obtainVerificationData{
    pcKey: string
  }
  
  export interface obtainGraphicVerificationModel{
    code: number;
    msg: string;
    data: Data
  }

  export interface obtainVerificationParams{
    gvcKey: string;
    code: string;
    phone: string;
  }

  export interface obtainRegisterParams{
    phone: string;
    password: string;
    code: string;
    pcCode: string;
    pcKey: string;
  }
    
}

// 获取图形验证码
export const obtainGraphicVerificationCode = () => {
  // 返回的数据格式可以和服务端约定
  return axios.get<Register.Data>("/api/skins_client/code/getGvc");
};

// 获取验证码
export const obtainVerificationCode = (params: Register.obtainVerificationParams) => {
  return axios.post<Register.obtainVerificationData>("/api/skins_client/code/getPc", params);
}

// 注册请求
export const register = (params: Register.obtainRegisterParams) => {
  return axios.post<Register.obtainVerificationData>("/api/skins_client/user", params);
}