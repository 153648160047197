import { useUserStore } from "@/store/user.store";
import { getCookie } from "@/utils/cookie";
import axios, {
  AxiosInstance,
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { ElMessage } from "element-plus";

// 数据返回的接口
// 定义请求响应参数，不含data
interface Result {
  code: number;
  msg: string;
  total?: number;
}

// 请求响应参数，包含data
interface ResultData<T = any> extends Result {
  data?: T;
}

const URL: string = import.meta.env.VITE_APP_BASE_API;

enum RequestEnums {
  TIMEOUT = 20000,
  SUCCESS = 200, // 请求成功
  TOKEN_EXPIRED = 4003, // token过期
  ACCOUNT_FORBIDDEN = 4007,
}

const config = {
  // 默认地址
  baseURL: URL as string,
  // 设置超时时间
  timeout: RequestEnums.TIMEOUT as number,
  // 跨域时候允许携带凭证
  withCredentials: true,
};

class RequestHttp {
  // 定义成员变量并指定类型
  service: AxiosInstance;

  public constructor(config: AxiosRequestConfig) {
    // 实例化axios
    this.service = axios.create(config);

    /**
     * 请求拦截器
     * 客户端发送请求 -> [请求拦截器] -> 服务器
     * token校验(JWT) : 接受服务器返回的token,存储到vuex/pinia/本地储存当中
     */
    this.service.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        if (getCookie("token")) {
          config.headers.token = getCookie("token");
        }
        return config;
      },
      (error: AxiosError) => {
        // 请求报错
        Promise.reject(error);
      }
    );

    /**
     * 响应拦截器
     * 服务器换返回信息 -> [拦截统一处理] -> 客户端JS获取到信息
     */
    this.service.interceptors.response.use(
      (response: AxiosResponse) => {
        const { data } = response;

        // 401 登录失败
        if (
          (data.code && +data.code === RequestEnums.TOKEN_EXPIRED) ||
          +data.code === RequestEnums.ACCOUNT_FORBIDDEN
        ) {
          return this.handleCode(data.code);
        }

        // 全局错误信息拦截（防止下载文件得时候返回数据流，没有code，直接报错）
        if (data.code && data.code !== RequestEnums.SUCCESS) {
          ElMessage({ message: data.msg, type: "error" });
          return Promise.reject(data);
        }
        return data;
      },
      (error: AxiosError) => {
        const { response } = error;
        if (response) {
          this.handleCode(response.status);
        }
        if (!window.navigator.onLine) {
          ElMessage.error("网络连接失败");
          // 可以跳转到错误页面，也可以不做操作
        }
      }
    );
  }
  handleCode(code: number): void {
    const userStore = useUserStore();
    switch (+code) {
      case RequestEnums.TOKEN_EXPIRED:
        if (userStore.isLogin) {
          userStore.logout();
        }
        break;
      case RequestEnums.ACCOUNT_FORBIDDEN:
        if (userStore.isLogin) {
          userStore.logout();
        }
        ElMessage.error("账号已冻结");
        break;
      default:
        ElMessage.error("请求失败");
        break;
    }
  }

  // 常用方法封装
  get<T>(
    url: string,
    params?: object,
    options?: AxiosRequestConfig
  ): Promise<ResultData<T>> {
    return this.service.get(url, { ...options, params });
  }

  post<T>(
    url: string,
    params?: object,
    options?: AxiosRequestConfig
  ): Promise<ResultData<T>> {
    return this.service.post(url, params, options);
  }

  put<T>(
    url: string,
    params?: object,
    options?: AxiosRequestConfig
  ): Promise<ResultData<T>> {
    return this.service.put(url, params, options);
  }

  delete<T>(
    url: string,
    params?: object,
    options?: AxiosRequestConfig
  ): Promise<ResultData<T>> {
    return this.service.delete(url, { ...options, params });
  }
}

// 导出一个实例对象
export default new RequestHttp(config);
