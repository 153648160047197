<template>
  <div class="flex flex-col items-center justify-center">
    <h3 class="mb-8 uppercase text-white">CDK兑换</h3>
    <p class="mb-10 mt-5 text-center text-xs text-navy-100">
      使用CDK兑换码，您可以将其兑换成平台余额！
    </p>
    <input
      class="input w-full pr-10"
      placeholder="输入您的CDK兑换码"
      v-model="cdkValue"
    /><button
      @click="exchange"
      v-preReClick
      :disabled="cdkValue === '' || cdkDisable"
      class="button button-primary mt-10"
    >
      <span>兑换</span>
    </button>
  </div>
</template>
<script lang="ts" setup>
import { redeemCdk } from "@/api/cdk";
import { useUserStore } from "@/store/user.store";
import { ElMessage } from "element-plus";
import { ref } from "vue";

const cdkDisable = ref(false);
const cdkValue = ref("");
const userStore = useUserStore();

const emit = defineEmits(["closeModel"]);
const exchange = async () => {
  try {
    cdkDisable.value = true;
    const cleanedString = cdkValue.value.replace(/\s+/g, '');
    const requestData = await redeemCdk({
      cdkCode: cleanedString,
    });
    emit("closeModel");
    if (requestData) {
      const { code } = requestData;
      if (code === 200) {
        ElMessage({
          message: "兑换成功。",
          type: "success",
        });
        // 获取用户信息
        userStore.updateUserMsg();
        setTimeout(() => {
          cdkDisable.value = false;
        }, 1000);
      }
    }
  } catch {
    cdkDisable.value = false;
  }
};
</script>
