<template>
  <div class="overall-from mt-11 w-full">
    <el-form
      class="m-auto xl:max-w-[426px] max-w-[360px]"
      :rules="rules"
      ref="ruleFormRef"
      :model="formData"
      :hide-required-asterisk="true"
    >
      <el-form-item prop="mobile">
        <el-input
          class="w-[426px] h-[48px]"
          v-model="formData.mobile"
          placeholder="请输入您的注册手机号"
        />
      </el-form-item>
      <el-form-item prop="graphicVerificationCode">
        <div class="flex w-full">
          <el-input
            class="h-[48px] flex-1"
            v-model="formData.graphicVerificationCode"
            placeholder="请输入图形验证码"
          >
          </el-input>
          <div
            class="ml-4 w-[136px] h-[46px] cursor-pointer"
            @click="getObtainGraphicVerificationCode"
          >
            <img v-if="imageSrc" :src="imageSrc" alt="未获取到图片" />
          </div>
        </div>
      </el-form-item>
      <el-form-item prop="code">
        <div class="flex w-full">
          <el-input
            class="h-[48px] flex-1"
            v-model="formData.code"
            placeholder="请输入短信验证码"
          >
          </el-input>
          <el-button
            v-if="!disabled"
            v-preReClick
            class="getCode ml-4 w-[136px]"
            type="primary"
            @click="send(ruleFormRef)"
            ><span style="color: #000000">获取验证码</span></el-button
          >
          <el-button
            v-else
            type="primary"
            class="getCode ml-4 w-[136px]"
            disabled
            v-preReClick
            ><span style="color: rgba(0, 0, 0, 0.5)"
              >{{ count }} 秒后重新发送</span
            ></el-button
          >
        </div>
      </el-form-item>
      <el-form-item
        prop="protocol"
        class="loginRegisterLable mb-0 mt-5 h-[16px] cursor-pointer text-xs"
      >
        <el-checkbox
          :class="triggerSubmission && !protocol ? 'checkboxError' : ''"
          v-model="protocol"
          style="height: 16px"
          size="large"
          ><div class="break-word text-[10px] leading-4 protocol">
            确认已年满18周岁，登录并同意
            <span
              @click.stop="openUserAgreement"
              class="text-regal-yello cursor-pointer"
            >
              《用户协议》
            </span>
            <span
              @click.stop="openPrivacyPolicy"
              class="text-regal-yello cursor-pointer"
            >
              《隐私政策》
            </span>
          </div></el-checkbox
        >
      </el-form-item>
      <el-form-item>
        <el-button
          v-preReClick
          class="login mt-8"
          type="primary"
          @click="submitForm(ruleFormRef)"
          >登录</el-button
        >
      </el-form-item>
    </el-form>
    <div class="text-sm sm:text-base text-body text-center">
      <button
        @click="verificationCodeLogin"
        type="button"
        v-preReClick
        class="text-sm sm:text-base text-heading underline font-bold hover:no-underline focus:outline-none"
      >
        账号密码登录
      </button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { onMounted, reactive, ref } from "vue";
import { ElMessage, FormInstance, FormRules } from "element-plus";
import REGS from "@/utils/reg";
import {
  obtainGraphicVerificationCode,
  obtainVerificationCode,
} from "@/api/register";
import { login } from "@/api/login";
import { useUserStore } from "@/store/user.store";
import { appStateStore } from "@/store/app.store";

const appStore = appStateStore();
const ruleFormRef = ref<FormInstance>();
const triggerSubmission = ref(false);
const oneYearOld = ref(false);
const protocol = ref(false);
const disabled = ref(false);
const total = ref(60);
const count = ref(0);
const imageSrc = ref("");
const gvcKey = ref("");
const pcKey = ref("");

const userStore = useUserStore();

// 在组件上定义发出的事件
const emit = defineEmits({
  switchComponents: null, /// 返回搜索参数
  closeModel: null, // 关闭弹窗
});

const verificationCodeLogin = () => {
  emit("switchComponents", 1);
};

// 手机号
const validate_mobile = (rule: any, value: any, callback: any) => {
  if (!value) {
    return callback(new Error("请输入您的注册手机号"));
  } else if (!REGS.mobile.test(value)) {
    return callback(new Error("请输入正确的手机号"));
  } else {
    return callback();
  }
};

// 图形验证码
const validate_graphic_code = (rule: any, value: any, callback: any) => {
  if (!value) {
    return callback(new Error("请输入图形验证码"));
  } else {
    return callback();
  }
};

// 验证码
const validate_code = (rule: any, value: any, callback: any) => {
  if (!value) {
    return callback(new Error("请输入验证码"));
  } else if (!REGS.code.test(value)) {
    return callback(new Error("请输入正确的验证码"));
  } else {
    return callback();
  }
};

// 年满18岁
const validate_oneYearOld = (rule: any, value: any, callback: any) => {
  if (!oneYearOld.value) {
    return callback(new Error());
  } else {
    return callback();
  }
};

// 各种协议
const validate_protocol = (rule: any, value: any, callback: any) => {
  if (!protocol.value) {
    return callback(new Error());
  } else {
    return callback();
  }
};

const rules = reactive<FormRules>({
  mobile: [{ validator: validate_mobile, trigger: "blur" }],
  graphicVerificationCode: [
    { validator: validate_graphic_code, trigger: "blur" },
  ],
  code: [{ validator: validate_code, trigger: "blur" }],
  oneYearOld: [{ validator: validate_oneYearOld, trigger: "blur" }],
  protocol: [{ validator: validate_protocol, trigger: "blur" }],
});

const formData = reactive({
  mobile: ref(""),
  code: ref(""),
  graphicVerificationCode: ref(""),
});

// 验证码登录
const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  triggerSubmission.value = true;
  await formEl.validate(async (valid, fields) => {
    if (valid) {
      const requestData = await login({
        phone: formData.mobile,
        code: formData.code,
        pcKey: pcKey.value,
        flag: "1",
      });
      if (requestData) {
        // 获取用户信息
        userStore.updateUserMsg();
        // 用户分配任务
        userStore.getSaveTask();
        // 更新用户库存信息
        userStore.getUserInventoryValue();
        // 更新全局配置
        userStore.updateGlobalConfig();
        ElMessage({
          message: "登录成功。",
          type: "success",
        });
        emit("closeModel");
      }
    }
  });
};

// 计时器处理器
const timerHandler = () => {
  count.value = total.value;
  disabled.value = true;

  let timer = setInterval(() => {
    if (count.value > 1 && count.value <= total.value) {
      count.value--;
    } else {
      disabled.value = false;
      clearInterval(timer);
    }
  }, 1000);
};

// 发送验证码
const send = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  // 先验证图形验证码是否正确
  formEl.validateField(
    ["graphicVerificationCode", "mobile"],
    async (isValid: boolean, invalidFields) => {
      // 将回调函数声明为 async
      // 验证通过后启动计时器
      if (isValid) {
        // 发送验证码
        const requestData = await obtainVerificationCode({
          gvcKey: gvcKey.value,
          code: formData.graphicVerificationCode,
          phone: formData.mobile,
        });
        if (requestData) {
          timerHandler();
          const { data, code } = requestData;
          if (code === 200) {
            pcKey.value = data?.pcKey as string;
            ElMessage({
              message: "验证码已发送，请查收。",
              type: "success",
            });
          }
        }
      }
    }
  );
};

const openUserAgreement = () => {
  // 打开用户协议的逻辑
  appStore.updateIsUserAgreementModel(true);
};
const openPrivacyPolicy = () => {
  // 打开隐私政策的逻辑
  appStore.updateIsPrivacyPolicyModel(true);
};

// 获取图形验证码
const getObtainGraphicVerificationCode = async () => {
  const requestData = await obtainGraphicVerificationCode();
  if (requestData) {
    const { data, code } = requestData;
    if (code === 200 && data) {
      gvcKey.value = data.gvcKey;
      const img = new Image();
      img.src = data.gvcCode;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        context?.drawImage(img, 0, 0);
        const imageURL = canvas.toDataURL("image/png");
        imageSrc.value = imageURL;
      };
    }
  }
};

onMounted(() => {
  getObtainGraphicVerificationCode();
});
</script>
<style lang="scss" scoped></style>
