<template>
  <div class="overall-from mt-11 w-full">
    <el-form
      class="m-auto xl:max-w-[426px] max-w-[360px]"
      :rules="rules"
      ref="ruleFormRef"
      :model="formData"
      :hide-required-asterisk="true"
    >
      <el-form-item prop="mobile">
        <el-input
          class="w-[426px] h-[48px]"
          v-model="formData.mobile"
          placeholder="请输入手机号"
        />
      </el-form-item>
      <el-form-item prop="graphicVerificationCode">
        <div class="flex w-full">
          <el-input
            class="h-[48px] flex-1"
            v-model="formData.graphicVerificationCode"
            placeholder="请输入图形验证码"
          >
          </el-input>
          <div
            class="ml-4 w-[136px] h-[46px] cursor-pointer"
            @click="getObtainGraphicVerificationCode"
          >
            <img v-if="imageSrc" :src="imageSrc" alt="未获取到图片" />
          </div>
        </div>
      </el-form-item>
      <el-form-item prop="code">
        <div class="flex w-full">
          <el-input
            class="h-[48px] flex-1"
            v-model="formData.shortMessageCode"
            placeholder="请输入短信验证码"
          >
          </el-input>
          <el-button
            v-if="!disabled"
            v-preReClick
            class="getCode ml-4 w-[136px]"
            type="primary"
            @click="send(ruleFormRef)"
            ><span style="color: #000000">获取验证码</span></el-button
          >
          <el-button
            v-else
            type="primary"
            class="getCode ml-4 w-[136px]"
            disabled
            v-preReClick
            ><span style="color: rgba(0, 0, 0, 0.5)"
              >{{ count }} 秒后重新发送</span
            ></el-button
          >
        </div>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          class="w-[426px] h-[48px]"
          v-model="formData.password"
          show-password
          placeholder="请输入密码"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          class="w-[426px] h-[48px]"
          v-model="formData.code"
          placeholder="请输入推广码（选填）"
        />
      </el-form-item>
      <el-form-item
        prop="protocol"
        class="loginRegisterLable mb-0 mt-5 h-[16px] cursor-pointer text-xs"
      >
        <el-checkbox
          :class="triggerSubmission && !protocol ? 'checkboxError' : ''"
          v-model="protocol"
          style="height: 16px"
          size="large"
          ><div class="break-word text-[10px] leading-4 protocol">
            确认已年满18周岁，注册并同意
            <span
              @click.stop="openUserAgreement"
              class="text-regal-yello cursor-pointer"
            >
              《用户协议》
            </span>
            <span
              @click.stop="openPrivacyPolicy"
              class="text-regal-yello cursor-pointer"
            >
              《隐私政策》
            </span>
          </div></el-checkbox
        >
      </el-form-item>
      <el-form-item>
        <el-button
          v-preReClick
          class="login mt-8"
          type="primary"
          @click="submitForm(ruleFormRef)"
          >注册</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script lang="ts" setup>
import { onMounted, reactive, ref } from "vue";
import { ElMessage, FormInstance, FormRules } from "element-plus";
import {
  obtainGraphicVerificationCode,
  obtainVerificationCode,
  register,
} from "@/api/register";
import REGS from "@/utils/reg";
import { useRoute } from "vue-router";
import { appStateStore } from "@/store/app.store";

const appStore = appStateStore();
const route = useRoute()
const ruleFormRef = ref<FormInstance>();
const triggerSubmission = ref(false);
const protocol = ref(false);
const disabled = ref(false);
const total = ref(60);
const count = ref(0);
const imageSrc = ref("");
const gvcKey = ref("");
const pcKey = ref("");

// 在组件上定义发出的事件
const emit = defineEmits({
  openLogin: null, // 打开登录弹窗
});

// 手机号
const validate_mobile = (rule: any, value: any, callback: any) => {
  if (!value) {
    return callback(new Error("请输入手机号"));
  } else if (!REGS.mobile.test(value)) {
    return callback(new Error("请输入正确的手机号"));
  } else {
    return callback();
  }
};

// 图形验证码
const validate_graphic_code = (rule: any, value: any, callback: any) => {
  if (!value) {
    return callback(new Error("请输入图形验证码"));
  } else {
    return callback();
  }
};

// 验证码
const validate_code = (rule: any, value: any, callback: any) => {
  if (!value) {
    return callback(new Error("请输入验证码"));
  } else if (!REGS.code.test(value)) {
    return callback(new Error("请输入正确的验证码"));
  } else {
    return callback();
  }
};

// 各种协议
const validate_protocol = (rule: any, value: any, callback: any) => {
  if (!protocol.value) {
    return callback(new Error());
  } else {
    return callback();
  }
};

const rules = reactive<FormRules>({
  mobile: [{ validator: validate_mobile, trigger: "blur" }],
  graphicVerificationCode: [
    { validator: validate_graphic_code, trigger: "blur" },
  ],
  shortMessageCode: [{ validator: validate_code, trigger: "blur" }],
  password: [{ required: true, message: "请输入密码", trigger: "blur" }],
  protocol: [{ validator: validate_protocol, trigger: "blur" }],
});

const formData = reactive({
  account: ref(""),
  mobile: ref(""),
  shortMessageCode: ref(""),
  graphicVerificationCode: ref(""),
  password: ref(""),
  code: ref(""),
});

if (route.query.code) {
  formData.code = route.query.code as string;
}

// 注册
const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  triggerSubmission.value = true;
  await formEl.validate(async (valid, fields) => {
    if (valid) {
      const requestData = await register({
        phone: formData.mobile,
        password: formData.password,
        pcCode: formData.shortMessageCode,
        pcKey: pcKey.value,
        code: formData.code,
      });
      if (requestData) {
        const { code } = requestData;
        if (code === 200) {
          ElMessage({
            message: "注册成功，请前往登录。",
            type: "success",
          });
          // 注册的手机号给到登录
          emit('openLogin', formData.mobile)
        }
      }
    }
  });
};

// 计时器处理器
const timerHandler = () => {
  count.value = total.value;
  disabled.value = true;

  let timer = setInterval(() => {
    if (count.value > 1 && count.value <= total.value) {
      count.value--;
    } else {
      disabled.value = false;
      clearInterval(timer);
    }
  }, 1000);
};

// 发送验证码
const send = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  // 先验证图形验证码是否正确
  formEl.validateField(
    ["graphicVerificationCode", "mobile"],
    async (isValid: boolean, invalidFields) => {
      // 将回调函数声明为 async
      // 验证通过后启动计时器
      if (isValid) {
        // 发送验证码
        const requestData = await obtainVerificationCode({
          gvcKey: gvcKey.value,
          code: formData.graphicVerificationCode,
          phone: formData.mobile,
        });
        if (requestData) {
          timerHandler();
          const { data, code } = requestData;
          if (code === 200) {
            pcKey.value = data?.pcKey as string;
            ElMessage({
              message: "验证码已发送，请查收。",
              type: "success",
            });
          }
        }
      }
    }
  );
};

const openUserAgreement = () => {
  // 打开用户协议的逻辑
  appStore.updateIsUserAgreementModel(true)
};
const openPrivacyPolicy = () => {
  // 打开隐私政策的逻辑
  appStore.updateIsPrivacyPolicyModel(true)
};

// 获取图形验证码
const getObtainGraphicVerificationCode = async () => {
  const requestData = await obtainGraphicVerificationCode();
  if (requestData) {
    const { data, code } = requestData;
    if (code === 200 && data) {
      gvcKey.value = data.gvcKey;
      const img = new Image();
      img.src = data.gvcCode;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        context?.drawImage(img, 0, 0);
        const imageURL = canvas.toDataURL("image/png");
        imageSrc.value = imageURL;
      };
    }
  }
};

onMounted(() => {
  getObtainGraphicVerificationCode();
});
</script>
<style lang="scss" scoped></style>
