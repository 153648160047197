const indexPage = () => import("@/views/index/index.vue");

export const IndexPagePath = "/";
export const IndexPageName = "IndexPage";

const routes = [
  {
    path: IndexPagePath,
    name: IndexPageName,
    component: indexPage,
    meta: {
      titleKey: "GlobalSupplierOfSteamAccessories",
      keepAlive: true,
    },
  },
];

export default routes;
