<template>
  <TransitionRoot appear :show="appStore.isUserAgreementModel" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/85" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="relativew-full w-[700px] transform overflow-hidden rounded-2xl bg-[#23232D] shadow-[0_10px_40px_#1d2021] p-6 text-left align-middle transition-all"
            >
              <div
                @click="appStore.updateIsUserAgreementModel(false)"
                class="absolute top-4 right-4 closeHover"
              >
                <iconpark-icon
                  color="#ffffff"
                  size="20"
                  name="guanbiclose"
                ></iconpark-icon>
              </div>
              <div class="pt-10">
                <p class="text-[#ffffff] text-[18px] font-bold pb-2">
                  用户协议
                </p>
                <p class="text-[13px] font-bold">
                  请阅读下方的协议。您必须同意这些协议条款才能完成 SurpSkins
                  上充值消费。
                </p>
                <div
                  class="text-[rgba(255,255,255,0.6)] text-[14px] mt-3 h-[500px] overflow-auto pr-3"
                >
                  <p class="pb-6">本政策发布日期：2024年06月18日</p>
                  <p class="pb-6">
                    【特别提示】本政策适用于我们提供的所有 SurpSkins
                    服务。当您使用我们任何单项服务时，您同意接受本政策以及我们在该单项服务中设置的特定隐私信息类政策条款（以下称“特定条款”）的保护，在此情况下特定条款与本政策条款同时对您产生效力。
                  </p>
                  <p class="pb-6">
                    如特定条款与本政策条款存在同类条款的不一致约定，则在特定条款约束范围内应以特定条款为准。如我们提供的某一单项服务不适用本政策的，该服务中会以适当方式明示排除适用本政策。
                  </p>
                  <p class="pb-6">
                    本《用户协议》连 SurpSkin
                    客户端上发布的任何其他政策、指引或网站公告（统称“本协议”）构成用户（“您”）和SurpSkin
                    开箱网站（以下简称“SurpSkin ”或“我们”）就您使用的SurpSkin
                    内服务达成的协议。
                  </p>
                  <p class="pb-6">
                    若您不同意或接受本协议中的任何条款，应立即停止注册程序。若您勾选“确认已年满十八岁，注册并同意《用户协议》和《隐私政策》、承诺理性消费”，并进行注册或通过接入、访问、浏览
                    SurpSkin
                    ，使用我们提供的服务，即视为您已阅读，理解《用户协议》，并同意受本《用户协议》的约束。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款。限制、免责条款可能以加粗和下划线形式提示您注意，您应重点阅读。
                  </p>
                  <p class="pb-6">
                    在阅读完本政策后，如您对本政策或与本政策相关的事宜有任何问题，您可通过本政策“联系我们”章节所列的反馈渠道联系我们，我们会尽快为您作出解答。
                  </p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    一、基础约定
                  </p>
                  <p class="pb-6">
                    1、协议范围：考虑到互联网服务以及产品频繁迭代更新等行业特点，为了更全面的界定您与平台之间的权利义务，本协议包括我们根据法律法规规定以及本协议制定的其他政策、规则、公告声明等（除非特有所指，合称为“本协议”），您也应当加以遵守。
                  </p>
                  <p class="pb-6">
                    2、服务范围：我们可能通过不断丰富的功能界面向您提供本协议项下的平台服务，包括但不限于移动应用程序（“APP”）、PC端网站、以及其他形式。具体以我们实时发布的服务功能界面范围为准。
                  </p>
                  <p class="pb-6">
                    3、修改与更新：我们有权遵照法律法规规定，在必要时修改本协议（包括适时制定并发布其他政策、规则、公告声明），更新后的协议条款将代替原来的协议并在法律规定的期限届满后正式生效，您可以在相关服务页面查阅最新版本的协议条款。本协议修改后，如果您继续使用平台服务，即表示您已接受修改后的协议。如果您不接受修改后的协议，则您有权利停止使用平台服务。您若有违反本协议的任何规定，我们有权随时依约采取相应措施并保留追究相关法律责任的权利。
                  </p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    二、服务内容
                  </p>
                  <p class="pb-6">
                    1、本《用户协议》项下的具体服务是指我们在SurpSkin
                    任何相关平台上不时向用户提供的全部或部分及服务（以下简称“产品”或“服务”）。请注意，您必须年满18周岁，方可使用SurpSkin
                    的服务。
                  </p>
                  <p class="pb-6">
                    2、在用户遵守本协议及相关法律法规的前提下，给予用户一项不可转让及非排他性的许可，以使用以下服务，其中包括：
                  </p>
                  <p class="pb-6">
                    （1）创建账号、设置昵称；查阅游戏规则、用户个人资料、使用聊天功能等；
                  </p>
                  <p class="pb-6">
                    （2）使用账号并允许的其他某一项或几项功能；
                  </p>
                  <p class="pb-6">
                    3、SurpSkin
                    提供的饰品均为CSGO饰品，仅可用于CSGO游戏中。SurpSkin
                    的全部或部分服务及各业务板块等，均系基于公平合理的算法提供的。该算法项下获得饰品的概率可能受到饰品价值等多种元素的影响。且其具有随机性的特点！我们也将在每日上午对概率进行常规更新，或尽快对库存量不足的饰品或皮肤进行替换处理，以使得玩家得到的信息最新且准确。我们建议您仔细阅读各板块的规则关注每日概率的变化，掌据饰品动态并坚持理性消费！
                  </p>
                  <p class="pb-6">
                    4、请注意，SurpSkin
                    上所有盲盒产品或以盲盒形式抽取或掉落的具有随机性的产品均具有射幸性质，开启后期待利益一次用尽。您在此确认并同意，所获饰品不可退货，不得在平台兑换为其他饰品，并且我们在SurpSkin
                    的任何平台、由SurpSkin
                    开设的官方沟通社交群（包括但不限于QQ群）及线下均不提供以法定货币回收或兑换饰品的服务。您在此确认并同意，不将SurpSkin
                    提供的任何产品或饰品通过任何路径兑换成法定货币（包括但不限于人民币）。您也同意，在SurpSkin
                    上各业务板块发生的积分消耗以及饰品的价值是合理的，符合您的期望，您无权向我们要求任何退换或赔偿。
                  </p>
                  <p class="pb-6">
                    5、请注意，SurpSkin
                    向您提供CSGO盲盒的开启及其他CSGO饰品抽取服务，仅旨在为您提供娱乐之目的，本平台禁止任何通过游戏币、虚拟币或者饰品进行变现或者反向兑换成为现金（包括但不限于人民币）的行为，若您在使用我们的服务过程中出现涉嫌赌博、饰品变现等情形的，我们有权对您的账号进行限制、封禁等处理，直至您的账号洗清所有不合法嫌疑，也欢迎您积极对本平台用户中出现的违规情形进行举报，您可以通过本协议第十五条与我们进行联系。
                  </p>
                  <p class="pb-6">
                    6、SurpSkin
                    作为一款公平、公正、概率公开的网站平台，我们保证我们提供的服务项目的概率均为真实，不存在任何的暗调概率、隐藏概率、虚构概率、截胡等行为，为您创造一个完全安心娱乐的CSGO盲盒平台。
                  </p>
                  <p class="pb-6">
                    7、在SurpSkin
                    上，您同意Steam用户协议的条款在所有方面都适用于您，特别包括Steam关于饰品提取/使用的一切规定。我们也提醒您关注Steam的隐私政策！另外，为了保障玩家的账户安全，提取环节可能存在身份核验、信息校对等流程性要求，请您及时关注及配合。若您需要任何帮助的，也可以随时联系SurpSkin
                    的客服，我们乐意进行解释并协助您完成提取。
                  </p>
                  <p class="pb-6">
                    8、您同意并认可，平台上的交易可能由于若干原因而失败，包括但不限于价格变化。我们不对任何交易的正常执行做出任何陈述或保证。我们特此声明：有权不负责因任何交易失败而产生、或与任何交易失败相关的所有损失和损害。您同意并承认我们并没有责任通知交易失败。我们可在任何时候拒绝执行交易、施加交易金额的限制或约束，无需事先通知，并可自行决定。具体而言，如果我们怀疑可能存在洗钱、赌博、资助恐怖主义、欺诈或任何其他非法行为，或者我们怀疑交易违反了相关服务条款，公司保留拒绝处理、取消或撤销任何交易和禁用用户帐户的权利。
                  </p>
                  <p class="pb-6">
                    9、您同意并认可，如果您或您的交易被我们标记为可疑，我们将要求您提供额外的证明文件，并可暂停您的任何交易、存款或提款，直至经我们自主判断排除任何可疑因素。您特此授权我们直接（或通过第三方）进行任何我们所认为必要的查询，以验证您的身份并
                  </p>
                  <p class="pb-6">
                    10、您不得使用任何形式的未经授权的第三方网站、脚本或类似应用（包括但不限于自动化网站、修改器、抓取程序、用户脚本、插件、扩展、作弊网站等）以任何方式和/或为数据收集目的修改、自动化和/或改变购买程序，同时您不得在使用服务时采用价格转向或价格诱导等做法。一经发现您有任何前述行为，我们有权单方决定自动终止您的有关账户，且您无权获得补偿或取回任何游戏币、饰品或积分。
                  </p>
                  <p class="pb-6">
                    （1）作为您使用网站和服务的条件之一，您承诺您不会：
                  </p>
                  <p class="pb-6 pl-[33px]">
                    冒充或虚报您与任何人士或实体的关联关系；
                  </p>
                  <p class="pb-6 pl-[33px]">
                    访问、篡改或使用网站和服务或我们的计算机系统的任何非公开区域；
                  </p>
                  <p class="pb-6 pl-[33px]">
                    试图探测、扫描或测试网站、服务或任何相关系统或网络的漏洞，或违反就网站、服务及该等系统和网络使用的任何安全或身份验证措施；
                  </p>
                  <p class="pb-6 pl-[33px]">
                    试图破译、反编译、反汇编、反向工程或另行调查用于提供网站或服务的任何网站或组件；
                  </p>
                  <p class="pb-6 pl-[33px]">
                    以任何方式损害或威胁损害其他用户，或干扰或试图干扰任何用户对主机或网络的访问，包括但不限于发送病毒、超载、洪泛、垃圾邮件或邮件炸弹，或其他方式；
                  </p>
                  <p class="pb-6">提供属于第三方的支付信息；</p>
                  <p class="pb-6 pl-[33px]">
                    以违背 SurpSkin
                    的经营目的、我们的政策和说明、您对任何第三方的义务或任何适用法律的方式滥用我们的产品或服务；
                  </p>
                  <p class="pb-6 pl-[33px]">
                    从服务进行数据或其他内容的系统性回收，以直接或间接创建或编译单一或多个下载、集合、汇编、数据库、目录或类似程序，不论是手动抑或通过使用机器人（bots）、爬网程序（crawlers）、网页爬虫（spiders）或其他方式；
                  </p>
                  <p class="pb-6 pl-[33px]">
                    以违背第三方提供网站和服务运行的必要设施和技术（如第三方支付或Valve）的条款和条件的方式利用网站和服务；
                  </p>
                  <p class="pb-6 pl-[33px]">
                    在使用或访问网站或服务时侵犯我们及任何第三方的知识产权，包括但不限于利用服务提供游戏币、虚拟币、饰品或积分；
                  </p>
                  <p class="pb-6 pl-[33px]">
                    利用、推广、链接至我们自行决定认为具有攻击性或造成对我们声誉的损害的材料，或提供该等材料的访问，包括但不限于不合法内容、色情、赌博、诈骗、洗钱等内容及其他视为攻击性或有损我们和/或服务的内容
                  </p>
                  <p class="pb-6 pl-[33px]">
                    如您有我们自行决定认为不合法、违反本《用户协议》的条款，或另行对其他用户、对网站和服务的使用造成不利影响或潜在风险的任何行为或活动，我们有权随时终止您的账户或对网站或服务的全部或部分功能的使用（由我们自行决定）。您确认我们可以选择，但无义务在终止您对服务或网站的使用前向您发送通知。
                  </p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    三、用户账号与密码安全
                  </p>
                  <p class="pb-6">
                    1、您知悉并理解，在您注册、登录平台后，我们会为您配置生成您在平台的账号。我们在此特别提醒您应妥善保管自己的账号和密码。当您使用完毕后，应安全退出。因您保管不善可能导致账号盗窃或密码丢失，责任由您自行承担。账户因您主动泄露或因您遭受他们诈骗等行为导致的损失及后果，我们并不承担责任，您应通过司法、行政等救济途径向侵权行为人主张权利。如需要我们提供必要的协助，请与我们联系。
                  </p>
                  <p class="pb-6">
                    2、您不应将自己的账号、密码转让或出借予他人使用。如您发现自己的账号糟他人非法使用，应立即通知我们，并有权通知我们采取措施暂停该账户的登录和使用。同时，您也应向我们提供与被停用账户注册信息一致的个人有效身份信息。我们核实您所提供的个人有效身份信息与注册的身份不一致的，我们有权拒绝上述请求。同时，平台并有权视情节严重程度作出暂时封停、禁止提现、永久禁用账号等处罚措施。此外，因计算机病毒、黑客行为、或您的保管疏忽等行为导致账号、密码糟他人非法使用，我们不承担任何责任。
                  </p>
                  <p class="pb-6">
                    3、我们也特别提醒您妥善保护好自己支付平台的账号及密码。若您发现任何第三方盗用您的支付信息进行充值的，您应第一时间联系您的支付服务提供商，对您的账号进行冻结并尽力阻值交易。若您的支付服务提供商确认您的账号是被盗用的，您可以向我们提供支付服务提供商出具的确认信息，我们也将尽力协助您减少损失。但请您谅解，在无法确认交易主体等交易细节的情况下，我们无法终止交易，您需要对账户或交易带来的任何损失承担责任。
                  </p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    四、用户个人信息保护
                  </p>
                  <p class="pb-6">
                    1、保护您的个人信息是SurpSkin 的一项基本原则，SurpSkin
                    将会采取合理的措施保护用户的个人信息安全，除法律法规规定的情形外。
                  </p>
                  <p class="pb-6 pl-[33px]">
                    (1)您在注册账号或使用服务的过程中，需要填写一些必要的信息，请保持这些信息的真实、准确、合法、有效并注意及时更新，以便我们向您提供及时有效的帮助，或更好的为您提供服务。
                  </p>
                  <p class="pb-6 pl-[33px]">
                    (2)根据相关法律法规和政策，请您填写真实的身份信息。若您填写的信息不完整或不准备，则可能无法使用服务或在使用的过程中受到限制。本平台有权对您在注册登录环节填写或提交的资料信息进行备案，且保留抽查、要求您补充提交、及时更新的权利。在法律有明确规定要求本平台作为平台服务提供者必须对部分用户（如平台卖方等）的信息进行核实的情况下，本平台将依法不时地对您的信息进行检查核实，您应当配合提供最新、真实、完整、有效的信息。
                  </p>
                  <p class="pb-6">
                    2、我们将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息及隐私安全，以免遭受未经权限的访问、使用或披露。
                  </p>
                  <p class="pb-6">
                    3、未经您的许可，SurpSkin 不得向任何第三方（不包括SurpSkin
                    的关联方）公开或共享您注册资料中的姓名、个人有效身份证号码、联系方式、家庭住址等个人身份信息，但下列情况除外：
                  </p>
                  <p class="pb-6">（1）您授权 SurpSkin 披露的；</p>
                  <p class="pb-6">（2）有关法律要求 SurpSkin 披露的；</p>
                  <p class="pb-6">
                    （3）司法机关或行政机关基于法定程序要求 SurpSkin 提供的；
                  </p>
                  <p class="pb-6">
                    （4）SurpSkin 为了维护自己合法权益而向您提起诉讼或者仲裁时；
                  </p>
                  <p class="pb-6">（5）为维护社会公众的利益。</p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    五、账户注销
                  </p>
                  <p class="pb-6">
                    1、如果您需要注销您的账号，请在申请注销前确认以下信息，以保证您的账号和财产安全
                  </p>
                  <p class="pb-6">
                    （1）您的账号处于正常使用状态，未发生过被盗、被停用等账号异常情形；
                  </p>
                  <p class="pb-6">
                    （2）该账号是通过我们的官方渠道注册、符合本《用户协议》及相关平台说明规范的账号，且该账号为您本人注册。
                  </p>
                  <p class="pb-6">（3）账号内无资金。包括但不限于：</p>
                  <p class="pb-6 pl-[33px]">账号背包内饰品已被消费完毕</p>
                  <p class="pb-6 pl-[33px]">账号中的平台币已被消费完毕</p>
                  <p class="pb-6 pl-[33px]">精英币已被消费完毕</p>
                  <p class="pb-6 pl-[33px]">账号内其他资金均已消费完毕</p>
                  <p class="pb-6">（4）账号未涉及任何争议纠纷。</p>
                  <p class="pb-6">2、特别提醒</p>
                  <p class="pb-6">
                    （1）我们将在核实您的身份后并清理账号资产并确认无纠纷争议后提供账号注销服务。账号一旦注销完成，将无法恢复，您与我们曾签署过的相关用户协议、其他权利义务性文件等相应终止（但我们与您之间已约定继续生效的或法律法规另有规定的除外），我们将停止为您提供任何服务；
                  </p>
                  <p class="pb-6">
                    （2）账号进入注销流程后，将在15个工作日之内为您完成注销，也就是说，在您已成功向提交了SurpSkin
                    账号注销申请后的15个工作日内（从成功提交申请之时起算）。在前述的15个工作日内，您无法登录与使用该账号。但是，在此期间内，您可以通过登录-放弃注销来恢复账号的正常使用。如超过前述期限后，您为终止注销的，该账号将被永久注销。注销完成后，与该账号相关的手机号、邮箱、实名及Steam账号等信息将无法再次进行使用注册。
                  </p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    六、使用服务的方式
                  </p>
                  <p class="pb-6">
                    1、您承诺以真实身份注册成为我们的用户，并保证所提供的个人身份资料信息真实、完整、有效，依据法律规定和必备条款约定对所提供的信息承担相应的法律责任。您以真实身份注册成为我们用户后，需要修改所提供的个人身份资料信息的，我们将及时、有效地为您提供该项服务。
                  </p>
                  <p class="pb-6">
                    2、您对所持账户产生的行为依法享有权利和承担责任（包括但不限于在线签署各类协议、发布信息、购买或出售商品或服务以及信息披露）。
                  </p>
                  <p class="pb-6">
                    3、除非您与我们另有约定，您同意服务仅为您个人使用。
                  </p>
                  <p class="pb-6">
                    4、您应当通过我们提供或认可的方式使用服务。您依本《用户协议与条款》条款所取得的权利不可转让。
                  </p>
                  <p class="pb-6">
                    5、您不得使用未经我们授权的插件、外挂或第三方工具对SurpSkin
                    提供的全部或部分服务进行干扰、破坏、修改或施加其他影响。
                  </p>
                  <p class="pb-6">
                    6、您通过服务对程序的使用受制于某些已经包含在规则中的行为准则。这些规则将在我们官方网站公开并不定期更新，所有通过服务使用程序的用户必须遵守。您有义务知晓、了解并遵守这些行为规则。我们保留权利决定何种行为违反规则，并对该等行为采取其认为合适的相应的处理措施（包括但不限于警告、扣除积分、暂时或永久冻结账户、拒绝提供服务等）。我们保留随时修改这些行为规则的权利。
                  </p>
                  <p class="pb-6">
                    7、您有权仅为个人用途使用服务和网站，但您无权未经我们的事先书面同意，以任何方式向其他方出售服务、授予在服务中的担保权益、或转让服务的复制品，或向他人出租、出借或许可服务，但本《用户协议》其他规定明确允许者除外。
                  </p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    七、用户行为规范
                  </p>
                  <p class="pb-6">
                    1、您保证合理地使用平台服务，并接受本协议和我们适时制定并发布的其他政策、规则、公告声明。
                  </p>
                  <p class="pb-6">2、行为禁止。</p>
                  <p class="pb-6">
                    （1）您可在本协议约定的范围内使用平台及服务，您不得利用平台从事以下行为：
                  </p>
                  <p class="pb-6 pl-[33px]">
                    超出授权、恶意使用平台服务、恶意虚构、评价等信息或数据；
                  </p>
                  <p class="pb-6 pl-[33px]">
                    利用平台发表、传送、传播、储存危害国家安全、国家统一、社会稳定的内容，或侮辱诽谤、色情、暴力、引起他人不安及任何违反国家法律法规政策的内容，或者设置含有上述内容的网名、角色名，发布、传送、传播违法广告信息、营销信息及垃圾信息等；
                  </p>
                  <p class="pb-6 pl-[33px]">
                    利用平台侵害他人知识产权、肖像权、隐私权、名誉权、个人信息等合法权利或权益；
                  </p>
                  <p class="pb-6 pl-[33px]">
                    进行任何危害计算机网络安全的行为，包括但不限于：使用未经许可的数据或进入未经许可的服务器（账户未经允许进公众计算机网络或者他人计算机系统并别除、修改、增加存储信息）；
                  </p>
                  <p class="pb-6 pl-[33px]">
                    未经许可，企图探查、扫描、测试本”软件"系统或网络的朝点或其它实施破坏网络安全的行为，企图干涉、破坏本”软件"系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为，伪造TCP/IP数据包名称或部分名称，利用平台及服务上传任何病毒、木马，或者蠕虫等危害网络健康的内容；进行任何破坏我们提供服务公平性或者其他影响应用正常秩序的行为，如主动或被动剧分、合伙作弊、使用外挂或者其他的作弊软件、利用BUG（又叫"漏"或者“缺路”）来获得不正当的非法利益，或者利用互联网或其他方式将外挂、作弊软件、BUG公之于众；
                  </p>
                  <p class="pb-6 pl-[33px]">
                    从事其他法律法规、政策及公序良俗、社会公德禁止的行为以及侵犯其他个人、公司、社会团体、组织的合法权益的行为。
                  </p>
                  <p class="pb-6 pl-[33px]">
                    您同意并认可，如果您或您的交易被我们标记为可疑，我们将要求您提供额外的证明文件，并可暂停您的任何交易、存款或提款，直至经我们自主判断排除任何可疑因素。您特此授权我们直接（或通过第三方）进行任何我们所认为必要的查询，以验证您的身份并/或防止欺诈、洗钱等违法违规行为的发生，包括查询您的身份信息（例如您的姓名、地址、过去地址或出生日期），查询与您关联的账户信息（例如姓名或账户余额)，并根据此类查询的结果采取我们认为合理且必要的行动。您还须授权可能收到此类查询或请求的任何和所有第三方对此类查询或请求做出全面响应。
                  </p>
                  <p class="pb-6 pl-[33px]">
                    您应当保证支付信息与注册信息一致，如我们发现您的支付信息和注册信息不一致的，我们有权查封您的账号。
                  </p>
                  <p class="pb-6 pl-[33px]">
                    您承诺：不得利用平台给予新用户的特殊政策、福利等而冒用他人的身份证信息进行注册；同时，您也不得以任何方式利用同一身份信息重复注册账号，经我发现您由前述行为的，平台有权立刻查封您的帐号。
                  </p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    八、信息内容规范
                  </p>
                  <p class="pb-6">
                    1、为了营造良好网络生态、保公民，法人和其他组织的合法权益。维护国家安全和公共利益，我们将根据《中华人民共和国国家安全法》《中华人民共和国网络安全法》《互联网信息服务管理办法》等法律、行政法规，自觉践行社会主义该心价值观，营造清朗的网络空间，并开展的弘扬正能量、处置违法和不良信息等相关活动。
                  </p>
                  <p class="pb-6">
                    （1）您不得制作、复制、发布含有下列内容的违法信息：
                  </p>
                  <p class="pb-6 pl-[33px]">反对宪法所确定的基本原则的；</p>
                  <p class="pb-6 pl-[33px]">
                    危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
                  </p>
                  <p class="pb-6 pl-[33px]">损害国家荣誉和利益的；</p>
                  <p class="pb-6 pl-[33px]">
                    歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；
                  </p>
                  <p class="pb-6 pl-[33px]">
                    宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；
                  </p>
                  <p class="pb-6 pl-[33px]">
                    煽动民族仇恨、民族歧视，破坏民族团结的；
                  </p>
                  <p class="pb-6 pl-[33px]">
                    破坏国家宗教政策，宣扬邪教和封建迷信的；
                  </p>
                  <p class="pb-6 pl-[33px]">
                    散布谣言，扰乱经济秩序和社会秩序的；
                  </p>
                  <p class="pb-6 pl-[33px]">
                    散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
                  </p>
                  <p class="pb-6 pl-[33px]">
                    侮辱或者诽谤他人，侵害他人名誉、隐私和其他合法权益的；
                  </p>
                  <p class="pb-6 pl-[33px]">法律法规禁止的其他内容。</p>
                  <p class="pb-6">
                    （3）我们将依法采取措施，防范和抵制制作、复制、发布含有下列内容的不良信息：
                  </p>
                  <p class="pb-6 pl-[33px]">
                    使用夸张标题，内容与标题严重不符的；
                  </p>
                  <p class="pb-6 pl-[33px]">炒作绯闻、丑闻、劣迹等的；</p>
                  <p class="pb-6 pl-[33px]">
                    不当评述自然灾害、重大事故等灾难的；
                  </p>
                  <p class="pb-6 pl-[33px]">
                    带有性暗示、性挑逗等易使人产生性联想的；
                  </p>
                  <p class="pb-6 pl-[33px]">
                    展现血腥、惊悚、残忍等致人身心不适的；
                  </p>
                  <p class="pb-6 pl-[33px]">煽动人群歧视、地域歧视等的；</p>
                  <p class="pb-6 pl-[33px]">宣扬低俗、庸俗、媚俗内容的；</p>
                  <p class="pb-6 pl-[33px]">
                    可能引发未成年人模仿不安全行为和违反社会公德行为、诱导未成年人不良嗜好等的；
                  </p>
                  <p class="pb-6 pl-[33px]">
                    其他对网络生态造成不良影响的内容。
                  </p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    九、信息或广告推送
                  </p>
                  <p class="pb-6">
                    您同意在接受我们提供的服务的同时，允许我们在遵守法律法规的前提下自行或由第三方广告商向您发送、展示广告、推广或宣传信息（包括商业与非商业信息）。您如对发送、推荐的广告或信息不感兴趣，您可以基于我们提供的相关技术选项，控制系统向您展示或不展示/减少展示相关类型的广告或信息。
                  </p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    十、知识产权等权益
                  </p>
                  <p class="pb-6">
                    1、我们对平台中所包含的受知识产权或其他法律保护的资料享有相应的权利；除依法由用户享有著作权的内容之外，平台的整体内容的知识产权包括但不限于软件、图片或程序）及平台上所有内容（包括但不限于著作、图片、档案、信息、数据、网站画面的安排、网页设计）均由本公司或其它权利人依法拥有其各项知识产权（包括但不限于商标权、专利权、著作权、商业秘密与专有技术等）归我们或关联公司所有，任何人未经本公司及权利人授权，不得擅自使用、修改、重制或者公开播送、散布、发行、公开发表，或者进行还原工程、解编或反向组绎。如有违反，除需承担因违反《著作权法》及相关法律的产生后果外，还应对本公司负损害赔偿责任（包括但不限于诉讼费用及律师费用等）。
                  </p>
                  <p class="pb-6">
                    2、我们在服务中提供的内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权归我们所有，您在使用服务中所产生的内容的知识产权归您或相关权利人所有。除我们另行说明外，在法律允许的范围内，您免费授予我们非排他的、无地域限制的许可使用（包括存储、使用、复制、修订、编辑、发布、展示、翻译、分发上述信息或制作派生作品，以已知或日后开发的形式、媒体或技术将上述信息纳入其它作品内等）及可再许可第三方使用的权利，以及可以自身名义对第三方侵权行为取证及提起诉讼的权利。
                  </p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    十一、遵守当地法律监管
                  </p>
                  <p class="pb-6">
                    1、您在使用服务时（特别包括某网站各平台及与某网站相关的各沟通群）须遵守法律法规，不得利用某网站的服务从事违法违规行为，包括但不限于：
                  </p>
                  <p class="pb-6">
                    (1)
                    发布、传送、传播、储存危害国家安全统一、破坏社会稳定、违反公序良俗、侮辱、诽谤、淫秽、暴力、赌博、诈骗、洗钱以及任何违反国家法律法规的内容；
                  </p>
                  <p class="pb-6">
                    (2)
                    发布、传送、传播、储存侵害他人知识产权、商业秘密等合法权利的内容；
                  </p>
                  <p class="pb-6">
                    (3) 恶意虚构事实、隐瞒真相以误导、欺骗他人；
                  </p>
                  <p class="pb-6">(4) 发布、传送、传播广告信息及垃圾信息；</p>
                  <p class="pb-6">(5) 违反宪法所确定的基本原则的；</p>
                  <p class="pb-6">(6)损害国家荣誉和利益的；</p>
                  <p class="pb-6">
                    (7)用户名歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣耀的；
                  </p>
                  <p class="pb-6">
                    (8) 宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义的；
                  </p>
                  <p class="pb-6">(9) 违背社会公德的；</p>
                  <p class="pb-6">(10) 其他法律法规禁止的行为。</p>
                  <p class="pb-6">
                    2、除非法律允许或我们许可，您不得从事下列行为：
                  </p>
                  <p class="pb-6">(1) 删除我们关于著作权的信息；</p>
                  <p class="pb-6">
                    (2)对网站进行反向工程、反向汇编、反向编译或者以其他方式尝试发现网站的源代码或其他保密内容，包括但不限于我们暂未主动公开呈现但已封存的游戏元素等；
                  </p>
                  <p class="pb-6">
                    (3)
                    对我们进行扫描、探查、测试，以检测、发现、查找其中可能存在的BUG或弱点；
                  </p>
                  <p class="pb-6">
                    (4)
                    对我们或者我们运行过程中释放到任何终端内存中的数据、网站运行过程中客户端与服务器端的交互数据，以及我们运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经合法授权的第三方工具/服务接入网站和相关系统；
                  </p>
                  <p class="pb-6">
                    (5)
                    修改或伪造我们运行中的指令、数据，增加、删减、变动网站的功能或运行效果，或者将用于上述用途的网站、方法进行运营或向公众传播，无论上述行为是否为商业目的；
                  </p>
                  <p class="pb-6">
                    (6)
                    通过非我们开发、授权的第三方网站、插件、外挂、系统，使用我们的服务，或制作、发布、传播非我们开发、授权的第三方网站、插件、外挂、系统；
                  </p>
                  <p class="pb-6">
                    (7)
                    对我们拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；
                  </p>
                  <p class="pb-6">
                    (8)
                    建立有关我们的镜像站点，或者进行网页（络）快照，或者利用架设服务器等方式，为他人提供与我们的服务完全相同或者类似的服务；
                  </p>
                  <p class="pb-6">
                    (9)
                    将我们的任意部分分离出来单独使用，或者进行其他的不符合本协议的使用；
                  </p>
                  <p class="pb-6">
                    (10) 使用、修改或遮盖我们的名称、商标或其它知识产权；
                  </p>
                  <p class="pb-6">(11) 其他未经我们明示授权的行为。</p>
                  <p class="pb-6">
                    2、您在使用服务时（特别包括某网站各平台及与某网站相关的各沟通群）若发生如下任何行为（以下简称“不当行为”）之一的，我们有权自主判断并根据情节严重程度，依据本《用户协议与条款》及相关规定，对您的账号采取限制措施，包括但不限于：
                  </p>
                  <p class="pb-6">
                    (1)
                    以某种方式暗示或伪称我们内部员工或某种特殊身份，企图得到不正当利益或影响其他用户权益的行为；
                  </p>
                  <p class="pb-6">
                    (2)
                    在任何平台或通过任何途径将在某网站获得的任何产品，包括但不限于饰品、积分、虚拟币、游戏币等进行兑现或者反向兑换为法定货币（包括但不限于人民币）或交易；
                  </p>
                  <p class="pb-6">
                    (3)
                    在我们中使用、上传违法或不当词语、字符及其他内容的行为，包括用于账号命名；
                  </p>
                  <p class="pb-6">
                    (4) 以任何方式破坏我们或影响某网站的正常进行，包括但不限于：
                  </p>
                  <p class="pb-6 pl-[33px]">
                    违规及（或）恶意注册、登录某网站的行为，包括但不限于恶意批量注册某网站账号，以及以破坏服务器鉴权、恶意挤服等方式登录某网站行为；
                  </p>
                  <p class="pb-6 pl-[33px]">
                    恶意实施可能导致某网站服务器宕机、卡顿，或导致其他某网站用户延迟等无法正常某网站的行为，如利用DDOS对某网站服务器或其他玩家进行网络流量攻击的行为等；
                  </p>
                  <p class="pb-6 pl-[33px]">
                    利用某网站漏洞实施获益、破坏某网站环境或给其他玩家造成不良影响的行为；
                  </p>
                  <p class="pb-6 pl-[33px]">
                    某网站数据表现异常，影响某网站公平的其他行为。
                  </p>
                  <p class="pb-6">(5) 使用各种私服、外挂行为；</p>
                  <p class="pb-6">
                    (6)
                    使用某网站同步器（具体包括但不限于使用键盘、鼠标等硬件同步器，以便可以使用一套键盘、鼠标，同时、同步控制多台电脑进行某网站的行为），以及使用其他各种可以让您在某网站效率或收益数据上表现异常的硬件；
                  </p>
                  <p class="pb-6">
                    (7)
                    传播非法言论或不当信息、造谣、诽谤、恶意诋毁我们、某网站平台和/或其用户；
                  </p>
                  <p class="pb-6">
                    (8) 盗取他人某网站账号、某网站账号内的任何物品；
                  </p>
                  <p class="pb-6">
                    (9) 进行某网站账号交易、账号共享等影响账号安全的行为；
                  </p>
                  <p class="pb-6">
                    (10)
                    在未经我们授权或认可的折扣渠道进行某网站充值或委托未获我们授权或认可的第三方代为充值，或者使用未经我们授权或认可的第三方网站充值。
                  </p>
                  <p class="pb-6">
                    (11) 违反本协议任何约定，或违反国家法律法规任何规定的行为；
                  </p>
                  <p class="pb-6">(12) 其他在行业内被广泛认可的不当行为。</p>
                  <p class="pb-6">4、您不得在某网站上发布以下内容：</p>
                  <p class="pb-6">(1) 国家禁止或限制的；</p>
                  <p class="pb-6">(2) 侵犯他人知识产权或者合法权利的；</p>
                  <p class="pb-6">(3) 网站明令禁止的。</p>
                  <p class="pb-6">
                    5、如果我们发现或收到他人举报您发布的信息违反本协议第十条任何约定的，我们有权进行独立判断并采取技术手段予以删除、屏蔽或断开链接。同时，我们有权视您的行为性质，采取包括但不限于暂停或终止服务，限制、冻结或终止某网站账户使用，追究法律责任等措施。您违反本条约定，导致任何第三方损害的，您应当独立承担责任；我们因此遭受损失的（包括但不限于第三方直接向我们主张赔偿等情形），您也应当一并赔偿。如果相关国家机关、机构或相关权利人可能会对您提起诉讼、罚款或采取其他制裁措施，并要求我们给予协助。造成损害的，您应依法予以赔偿，我们不承担任何责任。
                  </p>
                  <p class="pb-6">
                    6、您在使用服务过程中应当遵守当地相关的法律法规，并尊重当地的道德和风俗习惯。如果您的行为违反了当地法律法规或道德风俗，您应当为此独立承担责任。您应避免因使用服务而使我们卷入政治和公共事件，否则我们有权单方决定暂停或终止对您的服务。
                  </p>
                  <p class="pb-6">
                    7、您通过服务发送或传播的内容（包括但不限于网页、文字、图片、音频、视频、图表等）均由您自行承担责任。您发送或传播的内容应有合法来源，相关内容为您所有或您已获得权利人的授权。您同意我们可为履行本《用户协议与条款》或提供服务的目的而使用您发送或传播的内容。如果我们收到权利人通知，主张您发送或传播的内容侵犯其相关权利的，您同意我们有权进行独立判断并采取删除、屏蔽或断开链接等措施。
                  </p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    十二、服务的变更、中断或终止
                  </p>
                  <p class="pb-6">
                    1、用户同意，本平台可自行全权决定以任何理由（包括但不限于本平台认为您已违反本协议的字面意义和精神，或以不符合本协议的字面意义和精神的方式行事，或您在超过90天的时间内未以您的帐户及密码登录平台等）随时终止用户的“服务”密码、帐户（或其任何部份）或您对“服务”的使用，并删除（不再保存）用户在使用“服务”中提交的“用户资料”。同时本平台可自行全权决定，在发出通知或不发出通知的情况下，随时停止提供“服务”或其任何部分。帐户终止后，本平台没有义务为您保留原帐户中或与之相关的任何信息，或转发任何未曾阅读或发送的信息给您或第三方。此外，您同意，平台不就终止您接入“服务”而对您或任何第三者承担任何责任。
                  </p>
                  <p class="pb-6">
                    2、在用户向平台提出注销注册用户身份时，经本平台审核同意，由平台注销该注册用户，用户即解除与平台的服务协议关系。但注销该用户帐户后，本平台仍保留下列权利：
                  </p>
                  <p class="pb-6">
                    （1）用户注销后，平台有权保留该用户的注册资料及以前的交易行为记录；
                  </p>
                  <p class="pb-6">
                    （2）用户注销后，如用户在注销前在平台上存在违法行为或违反合同的行为，本平台仍可行使服务协议所规定的权利。
                  </p>
                  <p class="pb-6">
                    3、在下列情况下，平台可不经通知用户，以注销用户的方式终止服务：
                  </p>
                  <p class="pb-6">
                    （1）在用户违反服务协议相关规定时，本平台有权终止向该用户提供服务；
                  </p>
                  <p class="pb-6">
                    （2）如该用户在被本平台终止提供服务后，再一次直接或间接或以他人名义注册为本平台用户的，本平台有权再次单方面终止向该用户提供服务；
                  </p>
                  <p class="pb-6">
                    （3）如本平台通过用户提供的信息与用户联系时，发现用户在注册时填写的电子邮箱已不存在或无法接收电子邮件的，经本平台以其他联系方式通知用户更改，而用户在三个工作日内仍未能提供新的电子邮箱地址的，本平台有权终止向该用户提供服务；
                  </p>
                  <p class="pb-6">
                    （4）一旦本平台发现用户注册资料中主要内容是虚假的，本平台有权随时终止向该用户提供服务；
                  </p>
                  <p class="pb-6">
                    （5）本服务协议终止或更新时，用户未确认新的服务协议的；
                  </p>
                  <p class="pb-6">（6）其它本平台认为需终止服务的情况。</p>
                  <p class="pb-6">
                    4、服务中断、终止之前用户交易行为的处理。因用户违反法律法规或者违反服务协议规定而致使本平台中断、终止对用户服务的，对于服务中断、终止之前用户交易行为依下列原则处理：
                  </p>
                  <p class="pb-6">
                    （1）服务中断、终止之前，用户已经上传至本平台的物品尚未交易或尚未交易完成的，本平台有权在中断、终止服务的同时删除此项物品的相关信息。
                  </p>
                  <p class="pb-6">
                    （2）服务中断、终止之前，用户已经就其他用户出售的具体物品作出要约，但交易尚未结束，本平台有权在中断或终止服务的同时删除该用户的相关要约。
                  </p>
                  <p class="pb-6">
                    （3）服务中断、终止之前，用户已经与另一用户就具体交易达成一致，本平台可以不删除该项交易，但本平台有权在中断、终止服务的同时将用户被中断或终止服务的情况通知用户的交易对方。
                  </p>
                  <p class="pb-6">
                    5、因平台升级或维护或其他技术需要导致本平台需要中断服务的，本平台将在中断服务时通知用户。但任何原因导致本平台终止或中断服务，本公司及本平台均不承担任何责任，用户因此遭受的各项损失，由用户本人自行承担。
                  </p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    十三、免责声明
                  </p>
                  <p class="pb-6">
                    1、您理解并同意，在使用服务的过程中，可能会遇到不可抗力等风险因素，使服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，我们将努力在第一时间与相关单位配合，及时进行修复，但是由此给您造成的损失我们在法律允许的范围内免责。在法律允许的范围内，我们对以下情形导致的服务中断或受阻不承担责任：
                  </p>
                  <p class="pb-6">
                    (1) 受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；
                  </p>
                  <p class="pb-6">
                    (2) 您或我们的电脑网站、系统、硬件和通信线路出现故障；
                  </p>
                  <p class="pb-6">(3) 您操作不当；</p>
                  <p class="pb-6">(4) 您通过非我们授权的方式使用服务；</p>
                  <p class="pb-6">
                    2、我们对于您自平台而获得的所有他人、第三方信息、内容或者广告宣传等任何资讯（以下统称“信息”），但法律明确规定外，不保证真实、准确和完整性。如果任何单位或者个人通过上述“信息”而进行任何行为，您须自行甄别真伪和谨慎预防风险。您在接受平台及服务时，有可能会接触到令人不快、不适或令人厌恶的内容，在任何情况下，我们均不对任何此等内容承担任何责任。无论何种原因，我们不对任何非与我们直接发生的交易或行为承担任何直接、间接、附带或衍生的损失和责任。
                  </p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    十四、法律适用与管辖
                  </p>
                  <p class="pb-6">
                    1、本协议的订立、执行、解释及争议的解决均适用中华人民共和国法律。
                  </p>
                  <p class="pb-6">
                    2、如双方就平台服务协议内容或其履行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向协议签订地提起诉讼。本《用户协议》所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本《用户协议与条款》涵义解释的依据。本《用户协议》条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
                  </p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    十五、如何联系我们
                  </p>
                  <p class="pb-6">
                    1、如果您有任何的疑问、投诉、意见和建议，请与我们联系；您可以在网页任一界面右侧找到悬浮挂件，点击“客服”后，既可与我们取得联系！我们会给予您必要的帮助！
                  </p>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { appStateStore } from "@/store/app.store";

const appStore = appStateStore();

function closeModal() {
  appStore.updateIsUserAgreementModel(false)
}

</script>
