import axios from "../request";

export namespace UserCenter {
  export interface UserData{
    id: number;
    userId: string;
    avatar: string;
    phone: string;
    code: string;
    username: string;
    nickName: string;
    idCard: string;
    promotionCode: string;
    steamTradeURL: string;
    status: string;
    accountType: string;
    createTime: string;
  }
  
  export interface UserAccount{
    id: number;
    uId: string;
    balance: number;
  }
  
  export interface UserAccountCont{
    userInfo: UserData;
    userAccount: UserAccount;
  }
}

// 获取用户信息
export const getUserInfo = () => {
  // 返回的数据格式可以和服务端约定
  return axios.get<UserCenter.UserData>("/api/skins_client/user");
};

// 获取用户库存价值
export const getUserInventoryTotal = () => {
  return axios.get("/api/skins_client/inventory/totalSkinValue");
}

// 编辑用户名
export const editingInformation = (data: { username?: string, steamTradeURL?: string, promotionCode?: string, oldPromotionCode?: string, avatar?: string }) => {
  return axios.post("/api/skins_client/user/modifyInfo", data);
}

// 实名认证
export const realNameAuthentication = (data: { name: string, idCard: string }) => {
  return axios.post("/api/skins_client/user/checkIdCard", data);
}