<template>
  <!-- 实名认证 -->
  <TransitionRoot appear :show="isRealNameAuthenticationModel" as="template">
    <Dialog as="div" @close="closeModel" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/85" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-[447px] transform overflow-hidden rounded-2xl bg-[#23232D] shadow-[0_10px_40px_#1d2021] p-6 text-left align-middle transition-all"
            >
              <div
                @click="closeModel"
                class="absolute top-4 right-4 closeHover"
              >
                <iconpark-icon
                  color="#ffffff"
                  size="20"
                  name="guanbiclose"
                ></iconpark-icon>
              </div>
              <div class="flex flex-col justify-center overall-from">
                <h3 class="mb-8 uppercase text-white text-center">实名认证</h3>
                <p class="text-[12px] text-white">
                  1、为了保障您的账户安全，您需要完成实名认证才能进行充值操作。
                </p>
                <p class="text-[12px] text-regal-yello">
                  2、特别提醒：未满18岁的未成年人不得进行充值操作，请遵守相关规定。
                </p>
                <p class="text-[12px] text-white">3、感谢您的配合与理解。</p>
                <el-form
                  class="m-auto xl:w-full w-full mt-8"
                  :rules="rules"
                  ref="ruleFormRef"
                  :model="formData"
                  :hide-required-asterisk="true"
                >
                  <el-form-item prop="name">
                    <el-input
                      class="w-[426px] h-[48px]"
                      v-model="formData.name"
                      :maxlength="100"
                      placeholder="请输入您的姓名"
                    />
                  </el-form-item>
                  <el-form-item prop="idCard">
                    <el-input
                      class="w-[426px] h-[48px]"
                      v-model="formData.idCard"
                      placeholder="请输入您的身份证号"
                    />
                  </el-form-item>
                  <el-form-item class="mt-10">
                    <el-button
                      v-preReClick
                      class="login"
                      type="primary"
                      :disabled="loading"
                      :loading="loading"
                      @click="submitForm(ruleFormRef)"
                      >确认</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script lang="ts" setup>
import { appStateStore } from "@/store/app.store";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
} from "@headlessui/vue";
import { ElMessage, FormInstance, FormRules } from "element-plus";
import { reactive, ref, watch } from "vue";
import REGS from "@/utils/reg";
import { useUserStore } from "@/store/user.store";
import { realNameAuthentication } from "@/api/user/info";

const loading = ref(false);
const userStore = useUserStore();
const ruleFormRef = ref<FormInstance>();
const appStore = appStateStore();
const isRealNameAuthenticationModel = ref(false);

// 姓名
const validate_name = (rule: any, value: any, callback: any) => {
  if (!value) {
    return callback(new Error("请输入您的姓名"));
  } else if (!REGS.name.test(value)) {
    return callback(new Error("请输入您正确的姓名"));
  } else {
    return callback();
  }
};

// 身份证号
const validate_idCard = (rule: any, value: any, callback: any) => {
  if (!value) {
    return callback(new Error("请输入您的身份证号"));
  } else if (!REGS.idCard.test(value)) {
    return callback(new Error("请输入您正确的身份证号"));
  } else {
    return callback();
  }
};

const rules = reactive<FormRules>({
  name: [{ validator: validate_name, trigger: "blur" }],
  idCard: [{ validator: validate_idCard, trigger: "blur" }],
});

const formData = reactive({
  name: ref(""),
  idCard: ref(""),
});

watch(
  () => [appStore.isRealNameAuthenticationModel], // 修正这里的逗号
  () => {
    isRealNameAuthenticationModel.value =
      appStore.isRealNameAuthenticationModel;
  },
  {
    deep: true,
  }
);

// 关闭窗口
const closeModel = () => {
  isRealNameAuthenticationModel.value = false;
  appStore.updataIsRealNameAuthenticationModel(false);
};

// 实名认证
const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate(async (valid, fields) => {
    if (valid) {
      loading.value = true;
      try {
        const requestData = await realNameAuthentication({
          name: formData.name,
          idCard: formData.idCard,
        });
        if (requestData) {
          // 获取用户信息
          userStore.updateUserMsg();
          ElMessage({
            message: "实名认证通过。",
            type: "success",
          });
          closeModel();
          loading.value = false;
        }
      } catch (error) {
        formData.name = ''
        formData.idCard = ''
        loading.value = false;
        closeModel();
      }
    }
  });
};
</script>
