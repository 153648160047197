import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from "vue-router";
import index from "./modules"
import skins from "./modules/skins"
import battle from "./modules/battle"
import user from "./modules/user"
import youdao from "./modules/interior"

const routes: Array<RouteRecordRaw> = [
  // 登录/注册页面
  ...index,
  // 开箱页面
  ...skins,
  // 对战
  ...battle,
  // 用户中心
  ...user,
  // 有道词典
  ...youdao,
  // 通配路由
  { path: "/:pathMatch(.*)*", name: "not-found", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
