<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/85" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="relativew-full w-[700px] transform overflow-hidden rounded-2xl bg-[#23232D] shadow-[0_10px_40px_#1d2021] p-6 text-left align-middle transition-all"
            >
              <div
                @click="isOpen = false"
                class="absolute top-4 right-4 closeHover"
              >
                <iconpark-icon
                  color="#ffffff"
                  size="20"
                  name="guanbiclose"
                ></iconpark-icon>
              </div>
              <div class="pt-10">
                <p class="text-[#ffffff] text-[18px] font-bold pb-2">
                  常见问题
                </p>

                <div
                  class="text-[rgba(255,255,255,0.6)] text-[14px] mt-3 h-[500px] overflow-auto pr-3"
                >
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    我无法登录某些Steam网页?
                  </p>
                  <p class="pb-6">
                    当您尝试通过Steam账号登录 SurpSkins
                    时，可能会遇到“无法访问该网站”或“Steamcommunity.com拒绝了您的连接请求”的问题。解决办法是下载uu加速器（web、手机端通用）。运行软件后，搜索“加速STEAM社区”。部分用户可能需要使用Chrome浏览器。
                  </p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    为什么我在 SurpSkins 上的账号被封了?
                  </p>
                  <p class="pb-6">
                    这可能有多方面的原因，包括违反网站规则或条款，进行可疑行为或对其他用户有不当行为，以及与客服人员的沟通中有不当行为。请遵守所有规定。
                  </p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    我可以使用我朋友的交易链接吗？
                  </p>
                  <p class="pb-6">可以使用，但请尽快更改为您的交易链接。</p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    我如何保存我的Steam交易链接？
                  </p>
                  <p class="pb-6">
                    访问并登录链接：<a
                      class="underline cursor-pointer text-regal-yello"
                      href="https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url"
                      target="_blank"
                      >https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url</a
                    >，找到并复制您的交易链接，然后保存到您的 SurpSkins 账号页。
                  </p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    SurpSkins 有哪些发货选择?
                  </p>
                  <p class="pb-6">
                    正常发货由机器人自动发出。您可以在 SurpSkins
                    背包界面点击“取回STEAM”按钮预定取回您获得的游戏道具。道具需1-8天发送至您的游戏库存（可通过客服人员咨询进度）。以上责任方为STEAM7天交易条款，如介意等待时间，请关闭
                    SurpSkins 。
                  </p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    我拒绝/还价了机器人发送的报价，导致我无法取回 SurpSkins
                    库存饰品？
                  </p>
                  <p class="pb-6">
                    对于未接受的交易报价，您可以再次创建交易。因您的操作（拒绝/还价）导致的风控问题，请联系客服，并备注饰品名称。
                  </p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    我提取了CS2饰品，一直处于“提取中”状态：
                  </p>
                  <p class="pb-6">
                    机器人收到请求后，完成时间可能需要12小时。提取成功后，可“接受报价”取回CS2GO。
                  </p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    饰品至游戏库存要收提取费用吗？
                  </p>
                  <p class="pb-6">CS2饰品的提取过程是免费的。</p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    如何在 SurpSkins 提取CS 饰品？
                  </p>
                  <p class="pb-6">
                    获得饰品后，进入我的库存，点击"取回STEAM"按钮。在弹出的信息框中等待机器人发送饰品。当报价准备好，点击"接受"按钮，接受Steam上的交易报价。以下原因会导致报错：
                  </p>
                  <p class="pb-6 pl-[33px]">
                    Steam账户交易受限； Steam库存隐私设置错误；
                  </p>
                  <p class="pb-6 pl-[33px]">绑定的Steam交易链接错误；</p>
                  <p class="pb-6 pl-[33px]">
                    SurpSkins 的自动交易机器人故障或取消提取，请联系客服。
                  </p>
                  <p class="pb-6 pl-[33px]">Steam账号库存隐私设置不正确：</p>
                  <p class="pb-6 pl-[33px]">
                    Steam库存隐私状态需设为公开，点击链接设置<a
                      class="underline cursor-pointer text-regal-yello"
                      href="https://steamcommunity.com/id/me/edit/settings"
                      target="_blank"
                      >https://steamcommunity.com/id/me/edit/settings</a
                    >（需要加速器）。或在Steam账户→库存→更多→库存隐私设置→全部公开。
                  </p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    付款相关问题 如何充值？
                  </p>
                  <p class="pb-6">点击个人中心账户充值。</p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    值没有到账，我该怎么办？
                  </p>
                  <p class="pb-6">
                    请等候5-10分钟。如果还是没有到账，请联系客服，并提供网站注册账号ID和付款详情截图。
                  </p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    最少的充值金额是多少？
                  </p>
                  <p class="pb-6">
                    最少的充值金额是{{ userStore.minRecharge === 0 ? '10.00' : userStore.minRecharge }}。
                  </p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    怎样可以免费获得佣金？
                  </p>
                  <p class="pb-6">
                    留意我们的合作伙伴推广计划。邀请别人到网站并从他们的充值中获得一定比例的佣金。
                  </p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    我已经把链接发给了很多人，他们也使用了，但我没有收到奖金。
                  </p>
                  <p class="pb-6">
                    请与用户确认是否使用您的推广链接注册。如果使用了其他用户的推广链接，您将不会获得合作伙伴奖金。
                  </p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    我收到一个来自 SurpSkins 管理员的朋友请求。我可以相信他吗？
                  </p>
                  <p class="pb-6">
                    如果有人以 SurpSkins
                    管理员、调解员或机器人为昵称添加您，并声称他是团队成员，请记住：这是诈骗。请举报和拉黑用户。我们不会向您发送控制面板截图或从Steam用户直接购买任何皮肤，不会在Steam上添加您，也不会发送任何文件。
                  </p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    如何联系到我们？
                  </p>
                  <p class="pb-6">
                    您可以在网页任一界面右侧找到悬浮挂件，点击“客服”后，方可与我们取得联系！
                  </p>
                  <p class="pb-6">我们会给予您必要的帮助！</p>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { useUserStore } from "@/store/user.store";

const userStore = useUserStore();

const isOpen = ref(false);

function closeModal() {
  isOpen.value = false;
}

//暴露state和play方法
defineExpose({
  isOpen,
});
</script>
