import { defineStore } from "pinia";
import { useUserStore } from "./index";
export const appStateStore = defineStore({
  id: "app",
  state: () => {
    return {
      // 充值弹窗
      isRechargeModel: false,
      // 登录弹窗
      isLoginModel: false,
      // 实名认证
      isRealNameAuthenticationModel: false,
      // 用户协议
      isUserAgreementModel: false,
      // 隐私政策
      isPrivacyPolicyModel: false,
    } as {
      isRechargeModel: boolean;
      isLoginModel: boolean;
      isRealNameAuthenticationModel: boolean;
      isUserAgreementModel: boolean;
      isPrivacyPolicyModel: boolean;
    };
  },
  getters: {
    // 充值
    rechargeModel: (state) => state.isRechargeModel,
    // 登录
    loginModel: (state) => state.isLoginModel,
    // 实名认证
    realNameAuthenticationModel: (state) => state.isRealNameAuthenticationModel,
    // 网站地址
    websiteUrl: () => location.origin,
  },
  actions: {
    // 更新充值弹窗
    // 如果已经实名认证了，则显示充值弹窗，否则显示实名认证弹窗
    updateIsRechargeModel(show: boolean) {
      const userStore = useUserStore();
      if (show) {
        if (userStore.idCard) {
          this.isRechargeModel = show;
        } else {
          this.updataIsRealNameAuthenticationModel(true);
        }
      } else {
        this.isRechargeModel = show;
      }
    },

    // 更新登录弹窗
    updateIsLoginModel(show: boolean) {
      this.isLoginModel = show;
    },

    // 更新实名认证弹窗
    updataIsRealNameAuthenticationModel(show: boolean) {
      this.isRealNameAuthenticationModel = show;
    },

    // 更新用户协议弹窗
    updateIsUserAgreementModel(show: boolean) {
      this.isUserAgreementModel = show;
    },

    // 更新隐私政策弹窗
    updateIsPrivacyPolicyModel(show: boolean) {
      this.isPrivacyPolicyModel = show;
    }
  },
});
