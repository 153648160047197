<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/85" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="relativew-full w-[700px] transform overflow-hidden rounded-2xl bg-[#23232D] shadow-[0_10px_40px_#1d2021] p-6 text-left align-middle transition-all"
            >
              <div
                @click="isOpen = false"
                class="absolute top-4 right-4 closeHover"
              >
                <iconpark-icon
                  color="#ffffff"
                  size="20"
                  name="guanbiclose"
                ></iconpark-icon>
              </div>
              <div class="pt-10">
                <p class="text-[#ffffff] text-[18px] font-bold pb-2">
                  反洗钱协议
                </p>
                <div
                  class="text-[rgba(255,255,255,0.6)] text-[14px] mt-3 h-[500px] overflow-auto pr-3"
                >
                  <p class="pb-6">
                    感谢访问SurpSkins（包括但不限于SurpSkins.com，SurpSkins手机客户端或其他形式，以下合称'平台'）！
                    SurpSkins致力于为玩家提供更有趣的CSGO饰品获得方式。
                  </p>
                  <p class="pb-6">
                    SurpSkins由山西完美思域科技有限公司及其关联方（以下统称'我们'）运营，并竭诚为您提供优质服务。本《反洗钱条款》（以下简称'本条款'）连同SurpSkins上发布的任何其他政策、指引或网站公告（以下简称'《用户协议》'）构成用户（以下简称'您'）和山西完美思域科技有限公司（以下简称'公司'）之间就您使用SurpSkins内服务达成的协议。请您仔细阅读并确认：
                  </p>
                  <p class="pb-6">
                    1、在您接受我们的产品或服务时，应自觉遵守《中华人民共和国反洗钱法》及其他反洗钱相关的法律法规，且承诺于本平台的任何消费资金来源正当合法，现在或将来也不会发生任何违反与反洗钱相关的法律法规与监管条例。
                  </p>
                  <p class="pb-6">
                    2、您同意我们为履行《中华人民共和国反洗钱法》等相关法律法规规定的反洗钱义务，当我们合理怀疑您涉嫌与洗钱相关的非法行为或刑事风险时，我们有权向法律规定的反洗钱行政主管部门或其他有关政府部门（以下统称'政府部门'）检举、举报，且为配合政府部门反洗钱调查，我们可以收集或要求您主动提供政府部门要求的个人信息。当发生法律法规或监管规定的特定情形时，您还需配合我们提供有效身份证件的彩色照片或影印件或其他相关材料以供我们核对并留存。
                  </p>
                  <p class="pb-6">
                    3、出于合规性考量，如您不能提供或拒绝提供上述必要信息的，我们将可能对您的账号采取限制性措施、拒绝向您提供我们的产品或服务（包括但不限于冻结您在平台或SurpSkins账户下的虚拟货币、游戏饰品道具、积分、卡券、等级额度等任何虚拟财产）直至您提供了上述必要信息以及经过我们合理评估后认为您所涉嫌的洗钱相关刑事风险已经解除。
                  </p>
                  <p class="pb-6">
                    4、本条款与《用户协议》签订地保持一致，均为中华人民共和国山西省太原市。《用户协议》的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。若您和我们之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交《用户协议》签订地有管辖权的人民法院管辖。《用户协议》所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为《用户协议》涵义解释的依据。《用户协议》条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
                  </p>
                  <p class="pb-6">
                    5、本条款未约定的，参照《用户协议》约定的内容执行；本条款已经约定的内容以本条款约定为准，双方应严格遵守和履行。若您不同意本条款中的任何约定，请您不要使用任何SurpSkins的产品或服务，若您勾选‘确认已年满18周岁，注册并同意《用户协议》和《隐私政策》’，并进行注册或通过接入、访问、浏览SurpSkins，使用我们提供的服务，即视为您已阅读、理解《用户协议》，并同意受《用户协议》约束。请您务必审慎阅读、充分理解各条款内容。双方确认，前述免除或者限制责任的条款并非属于《中华人民共和国民法典》第497条规定的'减轻其责任、加重对方责任、限制对方'的条款或其他可适用的法律中对格式条款的特别约定，双方同意该条款的合法性及有效性。若您对本条款或《用户协议》有任何疑问，可随时通过客服人员联系我们。
                  </p>
                  <p class="pb-6">
                    6、您同意我们可以根据反洗钱的需要，不时对您的账户进行监控和审核，包括但不限于资金流动、交易记录等。我们有权在必要时采取适当的措施，包括但不限于暂停或终止账户的使用、拒绝交易等，以确保平台的安全和合规。
                  </p>
                  <p class="pb-6">
                    7、您保证在本平台进行的所有交易均为您本人合法意图的真实体现，不涉及任何形式的虚假交易、洗钱活动或其他非法行为。若发现任何可疑活动，您应及时向我们报告，并配合我们的调查。
                  </p>
                  <p class="pb-6">
                    8、我们承诺对您提供的所有信息严格保密，除非法律法规另有规定或政府部门依法要求披露。未经您的同意，我们不会向任何第三方泄露您的个人信息。
                  </p>
                  <p class="pb-6">
                    9、为了更好地保护您的账户安全，您应妥善保管自己的账户信息及密码，不得将账户借予他人使用。若您发现账户被盗用或存在安全漏洞，应立即通知我们，以便我们采取必要的保护措施。
                  </p>
                  <p class="pb-6">
                    10、本条款自发布之日起生效，若有任何修改或更新，我们将提前通知您，并通过平台公告、邮件或短信等方式告知。修改后的条款在通知规定的时间内生效，您继续使用平台服务即表示接受新的条款。
                  </p>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";

const isOpen = ref(false);

function closeModal() {
  isOpen.value = false;
}

//暴露state和play方法
defineExpose({
  isOpen,
});
</script>
