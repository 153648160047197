const skinsPage = () => import("@/views/skins/index.vue");

export const SkinsPagePath = "/skins";
export const SkinsPageName = "SkinsPage";

const routes = [
  {
    path: SkinsPagePath,
    name: SkinsPageName,
    component: skinsPage,
    meta: {
      titleKey: "GlobalSupplierOfSteamAccessories",
    },
  },
];

export default routes;
