<template>
  <div class="overall-from mt-11 w-full">
    <el-form
      class="m-auto xl:max-w-[426px] max-w-[360px]"
      :rules="rules"
      ref="ruleFormRef"
      :model="formData"
      :hide-required-asterisk="true"
    >
      <el-form-item prop="mobile">
        <el-input
          class="w-[426px] h-[48px]"
          v-model="formData.mobile"
          :maxlength="100"
          placeholder="请输入您的注册手机号"
        />
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          class="w-[426px] h-[48px]"
          v-model="formData.password"
          show-password
          :maxlength="16"
          placeholder="请输入密码"
        />
      </el-form-item>
      <el-form-item
        prop="protocol"
        class="loginRegisterLable mb-0 mt-5 h-[16px] cursor-pointer text-xs"
      >
        <el-checkbox
          :class="triggerSubmission && !protocol ? 'checkboxError' : ''"
          v-model="protocol"
          style="height: 16px"
          size="large"
          ><div class="break-word text-[10px] leading-4 protocol">
            确认已年满18周岁，登录并同意
            <span
              @click.stop="openUserAgreement"
              class="text-regal-yello cursor-pointer"
            >
              《用户协议》
            </span>
            <span
              @click.stop="openPrivacyPolicy"
              class="text-regal-yello cursor-pointer"
            >
              《隐私政策》
            </span>
          </div></el-checkbox
        >
      </el-form-item>
      <el-form-item class="mt-8">
        <el-button
          v-preReClick
          class="login"
          type="primary"
          @click="submitForm(ruleFormRef)"
          >登录</el-button
        >
      </el-form-item>
    </el-form>
    <div class="flex m-auto xl:max-w-[426px] max-w-[360px] justify-between">
      <div
        class="forgotPassword cursor-pointer text-xs"
        @click="forgotPassword"
      >
        忘记了密码?
      </div>
      <div
        class="register cursor-pointer text-xs text-regal-yello"
        @click="verificationCodeLogin"
      >
        验证码登录
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { reactive, ref } from "vue";
import { ElMessage, FormInstance, FormRules } from "element-plus";
import { login } from "@/api/login";
import { appStateStore, useUserStore } from "@/store";

const props = defineProps({
  mobile: {
    type: String,
    default: "",
  },
});

const appStore = appStateStore();
const ruleFormRef = ref<FormInstance>();
const triggerSubmission = ref(false);
const protocol = ref(false);
const userStore = useUserStore();

// 在组件上定义发出的事件
const emit = defineEmits({
  switchComponents: null, /// 返回搜索参数
  closeModel: null, // 关闭弹窗
});

const verificationCodeLogin = () => {
  emit("switchComponents", 3);
};

const forgotPassword = () => {
  emit("switchComponents", 4);
};

// 账号
const validate_account = (rule: any, value: any, callback: any) => {
  if (!value) {
    return callback(new Error("请输入您的注册手机号"));
  } else {
    return callback();
  }
};

// 密码
const validate_password = (rule: any, value: any, callback: any) => {
  if (!value) {
    return callback(new Error("请输入密码"));
  } else {
    return callback();
  }
};

// 各种协议
const validate_protocol = (rule: any, value: any, callback: any) => {
  if (!protocol.value) {
    return callback(new Error());
  } else {
    return callback();
  }
};

const rules = reactive<FormRules>({
  mobile: [{ validator: validate_account, trigger: "blur" }],
  password: [{ validator: validate_password, trigger: "blur" }],
  protocol: [{ validator: validate_protocol, trigger: "blur" }],
});

const formData = reactive({
  mobile: ref(""),
  password: ref(""),
});

if (props?.mobile) {
  formData.mobile = props?.mobile;
}

// 账号密码登录
const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  triggerSubmission.value = true;
  await formEl.validate(async (valid, fields) => {
    if (valid) {
      const requestData = await login({
        phone: formData.mobile,
        password: formData.password,
        flag: "0",
      });
      if (requestData) {
        // 获取用户信息
        userStore.updateUserMsg();
        // 用户分配任务
        userStore.getSaveTask();
        // 更新用户库存信息
        userStore.getUserInventoryValue();
        // 更新全局配置
        userStore.updateGlobalConfig();
        ElMessage({
          message: "登录成功。",
          type: "success",
        });
        emit("closeModel");
      }
    }
  });
};

const openUserAgreement = () => {
  // 打开用户协议的逻辑
  appStore.updateIsUserAgreementModel(true)
};
const openPrivacyPolicy = () => {
  // 打开隐私政策的逻辑
  appStore.updateIsPrivacyPolicyModel(true)
};
</script>
<style lang="scss" scoped></style>
