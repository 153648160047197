<template>
  <TransitionRoot appear :show="appStore.isPrivacyPolicyModel" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/85" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="relativew-full w-[700px] transform overflow-hidden rounded-2xl bg-[#23232D] shadow-[0_10px_40px_#1d2021] p-6 text-left align-middle transition-all"
            >
              <div
                @click="appStore.updateIsPrivacyPolicyModel(false)"
                class="absolute top-4 right-4 closeHover"
              >
                <iconpark-icon
                  color="#ffffff"
                  size="20"
                  name="guanbiclose"
                ></iconpark-icon>
              </div>
              <div class="pt-10">
                <p class="text-[#ffffff] text-[18px] font-bold pb-2">
                  隐私政策
                </p>
                <p class="text-[13px] font-bold">
                  请阅读下方的协议。您必须同意这些协议条款才能完成 SurpSkins
                  上充值消费。
                </p>
                <div
                  class="text-[rgba(255,255,255,0.6)] text-[14px] mt-3 h-[500px] overflow-auto pr-3"
                >
                  <p class="pb-6">本政策发布日期：2024年06月18日</p>
                  <p class="pb-6">
                    SurpSkin
                    尊重并保护所有使用服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，
                    SurpSkin 会按照本隐私权政策的规定使用和披露您的个人信息。但
                    SurpSkin
                    将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，
                    SurpSkin 不会将这些信息对外披露或向第三方提供。 SurpSkin
                    会不时更新本隐私权政策。 您在同意 SurpSkin
                    服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于
                    SurpSkin 服务使用协议不可分割的一部分。
                  </p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    一、个人信息的收集和使用
                  </p>
                  <p class="pb-6">
                    （一）个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。我们仅会出于本政策所述的以下目的，收集和使用您的个人信息，如果您不提供相关信息，可能无法注册成为我们的用户或无法享受我们提供的某些产品或服务。
                  </p>
                  <p class="pb-6">
                    （二）当您使用 SurpSkin
                    服务时，我们可能收集和汇总或要求您提供有关个人身份的信息，(包括个人电话号码、系统账号、IP地址、电子邮箱地址等)，个人日志信息（包括网站浏览记录、软件使用记录、点击记录、操作日志等），设备信息（包括设备型号、设备MAC地址、操作系统类型、设备设置），
                    软件列表唯一设备识别码（IME/android
                    ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息等在内的描述个人常用设备基本情况的信息）。我们收集您的信息主要是为了您和其他用户能够更容易和更满意地使用
                    SurpSkin 服务。
                  </p>
                  <p class="pb-6">（三）帮助您完成注册及登录</p>
                  <p class="pb-6">
                    为便于我们向您提供服务，您需要提供基本的注册信息：
                  </p>
                  <p class="pb-6">
                    1、通过手机号码注册的：我们将收集您的手机号。如果您希望设置昵称及密码，方便之后使用昵称及密码登录的，我们也将收集前述信息；
                  </p>
                  <p class="pb-6">
                    2、通过邮箱注册的：我们将收集您的手机号、电子邮箱地址。如果您希望设置昵称及密码，方便之后使用呢称及密码登录的，我们也将收集前述信息；
                  </p>
                  <p class="pb-6">
                    3、使用Steam账号快速登录的，我们将收集您的Steam使用者名称，并进一步要求绑定手机号。我们建议您同样关注Steam所独立设置的隐私政策及用户权益，并关注您的信息如何被收集、使用、储存及提供。
                  </p>
                  <p class="pb-6">
                    您可以进一步设置您的个人头像。我们将对个人头像进行审核，对含有黄、赌、毒、广告、竞争平台宣传等违法或违规的头像，我们将审核不通过，审核不通过的头像将无法显示。同时有权选择对该账号在
                    SurpSkin 平台是否封禁。
                  </p>
                  <p class="pb-6">
                    为免疑义，如果您仅需在 SurpSkin
                    浏览、搜索等基本服务，您不需要注册成为 SurpSkin
                    用户及提供上述信息。
                  </p>
                  <p class="pb-6">（四）向您提供商品或服务</p>
                  <p class="pb-6">
                    1、我们所收集和使用的信息是为您提供 SurpSkin
                    服务的必要条件，如缺少相关信息，我们将无法为您提供 SurpSkin
                    相应的服务：
                  </p>
                  <p class="pb-6">
                    （1）在部分服务版块(如幸运好礼)中，为便于向您交付实体商品，您需提供收货人个人身份证类型、身份证号码、姓名、收货地址、邮政编码、联系电话。如果您拒绝提供此类信息，我们将无法完成相关交付服务。如您通过
                    SurpSkin
                    服务为其他人订购商品或服务，您需要提供该实际订购人的前述信息。向我们提供该实际订购人的前述信息之前，你需确保您已经取得其授权同意。
                  </p>
                  <p class="pb-6">
                    （2）为展示您账户的订单信息（包括充值记录、消耗记录、奖品奖励等），我们会收集您在使用
                    SurpSkin
                    服务过程中产生的订单信息（包括金额、支付渠道、创建时间、状态），用于向您展示及便于您对订单进行管理；为保证产品机制的公平及公开，我们在
                    SurpSkin 的部分业务版块
                  </p>
                  <p class="pb-6">
                    （3）当您与我们联系时(包括您与客服联系的时候)，我们可能会保存您与我们的通信/通话记录和内容或您留下的联系方式等信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。
                  </p>
                  <p class="pb-6">
                    （4）为确认交易状态及为您提供售后与争议解决服务，我们会通过您基于交易所选择的交易对象、支付机构、物流公司等收集与交易进度相关的您的交易信息(包括支付时间、支付方式、支付金额)、物流信息(包括收件人信息、寄件状态)，或将您的交易信息共享给上述服务提供者。同时请注意，为了核实账号归属情况及实际使用情况，在特定售后服务中，我们会要求您提供身份证件扫描件、户口本扫描件、支付账户基本信息等。在您提供前述信息前，我们将再次获得您的同意。
                  </p>
                  <p class="pb-6">
                    （5）为了维护 SurpSkin
                    基本功能的正常运营，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如您的搜索查询内容、浏览器的类型、电信运营商、使用的语言、网页记录、访问日期和时间以及设备所在位置相关信息(例如IP
                    地址、模糊定位以及能够提供相关信息的WLAN接入点)。
                  </p>
                  <p class="pb-6">
                    请注意，单独的设备信息、日志信息是无法识别特定自然人身份的信息，如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。这些信息在结合使用期间，将作为您的个人信息按照本《隐私政策》处理与保护。
                  </p>
                  <p class="pb-6">（五）为您提供安全保障</p>
                  <p class="pb-6">
                    1、请注意，为确保您身份真实性、向您提供更好的安全保障，在您进行任何充值行为前，我们要求您进行实名认证，您可以向我们提供身份证、军官证、护照、驾驶证、社保卡、居住证等身份信息、面部特征等生物识别信息等个人敏感信息完成实名认证。如果您拒绝提供上述信息从而无法完成身份认证，您将无法完整使用我们的产品/服务。
                  </p>
                  <p class="pb-6">
                    为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或本网站相关协议规则的情况，我们可能使用或整合您的会员信息、交易信息、设备信息、有关网络日志以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
                  </p>
                  <p class="pb-6">（六）其他用途</p>
                  <p class="pb-6">
                    我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。
                  </p>
                  <p class="pb-6">（七）征得授权同意的例外</p>
                  <p class="pb-6">
                    1、根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意：
                  </p>
                  <p class="pb-6">（1）与国家安全、国防安全有关的；</p>
                  <p class="pb-6">
                    （2）与公共安全、公共卫生、重大公共利益有关的；
                  </p>
                  <p class="pb-6">
                    （3）与犯罪侦查、起诉、审判和判决执行等有关的；
                  </p>
                  <p class="pb-6">
                    （4）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
                  </p>
                  <p class="pb-6">
                    （5）所收集的个人信息是您自行向社会公众公开的；
                  </p>
                  <p class="pb-6">
                    （6）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
                  </p>
                  <p class="pb-6">（7）根据您的要求签订合同所必需的；</p>
                  <p class="pb-6">
                    （8）用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；
                  </p>
                  <p class="pb-6">（9）为合法的新闻报道所必需的；</p>
                  <p class="pb-6">
                    （10）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
                  </p>
                  <p class="pb-6">（11）律法规规定的其他情形。</p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    二、Cookie的使用
                  </p>
                  <p class="pb-6">
                    （一）为确保服务正常运转，我们会在您的计算机或移动设备上存储名为Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，网站及客户端能够存储您的注册账号、邮箱、密码、偏好、银行账号、网络支付和
                  </p>
                  <p class="pb-6">
                    （二）您可根据自己的偏好管理或删除 Cookie。
                  </p>
                  <p class="pb-6">
                    您可以清除计算机上保存的所有
                    Cookie，大部分网络浏览器都设有阻止 Cookie
                    的功能。但如果您这么做，则需要在每一次访问我们的网站时更改用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。
                  </p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    三、个人信息的共享、转让、公开披露等
                  </p>
                  <p class="pb-6">（一）提供</p>
                  <p class="pb-6">
                    1、除以下情形外，未经您同意，我们不会向任何第三方提供您的信息：
                  </p>
                  <p class="pb-6">
                    （1）向您提供我们的服务。获得您的明确同意后，我们会与其他方提供您的个人信息，以实现您需要的功能或提供您需要的服务；
                  </p>
                  <p class="pb-6">
                    （2）维护和改善我们的服务。我们可能向合作伙伴及其他第三方提供您的信息，以帮助我们为您提供更有针对性、更完善的服务；
                  </p>
                  <p class="pb-6">
                    （3）实现本《隐私政策》第二条“我们如何收集和使用信息”部分所述目的
                  </p>
                  <p class="pb-6">
                    （4）履行我们在本《隐私政策》或我们与您达成的其他协议中的义务和行使我们的权利；
                  </p>
                  <p class="pb-6">
                    （5）为便于我们基于关联账号共同向您提供服务，推荐您可能感兴趣的信息，但我们只会共享必要的个人信息（如为便于您使用本网站账号使用我们关联公司产品或服务，我们会向"关联公司共享您必要的账户信息），如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。
                  </p>
                  <p class="pb-6">
                    （6）在法定情形下的提供：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外提供您的个人信息。
                  </p>
                  <p class="pb-6">（二）转让</p>
                  <p class="pb-6">
                    1、除以下情形外，未经您同意，我们不会向任何第三方转让您的信息：
                  </p>
                  <p class="pb-6">
                    （1）在获得您的明确同意后，我们会向其他方转让您的个人信息。
                  </p>
                  <p class="pb-6">
                    （2）随着业务的持续发展，在服务提供者发生合并、收购、资产转让或类似情形时，如涉及到个人信息转让，我们会要求新的持有您信息的公司、组织继续受本《隐私政策》的约束，否则，我们将要求该公司、组织重新向您征求授权同意。
                  </p>
                  <p class="pb-6">（三）披露</p>
                  <p class="pb-6">
                    1、我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会披露您的信息：
                  </p>
                  <p class="pb-6">
                    （1）获得您明确同意或基于您的主动选择，披露您所指定的信息；
                  </p>
                  <p class="pb-6">
                    （2）根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您信息的情况下，我们可能会依据所要求的信息类型和披露方式披露您的信息，在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求接收方必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据；
                  </p>
                  <p class="pb-6">
                    （3）为应对突发公共卫生事件，或者紧急状况下为保护自然人的生命健康和财产安全所必须；
                  </p>
                  <p class="pb-6">（4）法律、法规规定的其他情形。</p>
                  <p class="pb-6">
                    （四）共享、转让、公开披露个人信息时征得授权同意的例外
                  </p>
                  <p class="pb-6">
                    1、以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：
                  </p>
                  <p class="pb-6">（1）与国家安全、国防安全有关的；</p>
                  <p class="pb-6">
                    （2）与公共安全、公共卫生、重大公共利益有关的；
                  </p>
                  <p class="pb-6">
                    （3）与犯罪侦查、起诉、审判和判决执行等有关的；
                  </p>
                  <p class="pb-6">
                    （4）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
                  </p>
                  <p class="pb-6">（5）您自行向社会公众公开的个人信息；</p>
                  <p class="pb-6">
                    （6）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道，
                  </p>
                  <p class="pb-6">
                    根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
                  </p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    四、个人信息的保护
                  </p>
                  <p class="pb-6">
                    （一）所有因在中国大陆地区运营中收集和产生的个人信息，将被储存在中国境内。
                  </p>
                  <p class="pb-6">
                    我们仅在本《隐私政策》所述收集及处理个人信息的目的所必需期间和法律法规要求的最短时限内储存您的个人信息。如我们终止服务或运营，我们将及时停止继续收集您个人信息的活动，同时我们会遵守相关法律法规要求，采取例如推送通知、公告等形式向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理，但法律法规或监管部门另有规定的除外。
                  </p>
                  <p class="pb-6">我们将采取以下手段保护您的信息：</p>
                  <p class="pb-6">
                    1、我们将采取符合业界标准、合理可行的安全防护措施保护您提供的个人信息安全，防止个人信息遭到未经授权访问、开披露、使用、修改、损坏或丢失。我们会通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范信息的存储和使用来保护您的个人信息；我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击，我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
                  </p>
                  <p class="pb-6">
                    2、我们仅允许有必要知晓这些信息的 SurpSkin
                    员工、合作伙伴访问您的信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您的信息的所有人员履行相应的保密义务，如果未能履行这些义务；可能会被追究法律责任或被中止与
                    SurpSkin 的合作关系。
                  </p>
                  <p class="pb-6">
                    3、我们会采取一切合理可行的措施，确保不收集无关的信息，我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。
                  </p>
                  <p class="pb-6">
                    4、互联网并非绝对安全的环境，我们强烈建议您不要使用非本网站推荐的通信方式发送个人信息。
                  </p>
                  <p class="pb-6">
                    5、电子邮件、即时通讯、社交软件或其他服务软件等与其他用户的交流方式无法确定是否完全加密，建议您使用此类工具时请使用复杂密码，并注意保护您的信息安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
                  </p>
                  <p class="pb-6">
                    6、我们将不定期更新并公开安全风险、个人信息安全影响评估报告等有关内容，您可通过本网站公告方式获得。
                  </p>
                  <p class="pb-6">
                    7、为应对信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。我们也为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。
                  </p>
                  <p class="pb-6">
                    8、在不幸发生信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报信息安全事件的处置情况。
                  </p>
                  <p class="pb-6">
                    9、请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证信息百分之百的安全。您需要了解，您接入
                    SurpSkin
                    服务所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。
                  </p>
                  <p class="pb-6 text-xl text-[#ffffff] font-bold">
                    五、用户权益
                  </p>
                  <p class="pb-6">
                    您在使用 SurpSkin
                    的产品/服务的过程中，可以了解及管理您提供的注册信息和其他个人信息，也可按照本《隐私政策》第七条所列方式与我们联系。您访问、修改和删除个人信息的范围和方式将取决于您使用的具体服务。
                  </p>
                  <p class="pb-6">
                    （一）访问您的个人信息及修改、更正、转移您的个人信息
                  </p>
                  <p class="pb-6">（二）更正或补充您的个人信息</p>
                  <p class="pb-6">
                    当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正或补充。您可以通过“（一）访问您的个人信息”中列明的方式提出更正或补充申请。
                  </p>
                  <p class="pb-6">（三）删除您的个人信息</p>
                  <p class="pb-6">
                    1、在以下情形中，您可以向我们提出删除个人信息的请求：
                  </p>
                  <p class="pb-6">
                    （1）如果我们处理个人信息的行为违反法律法规；
                  </p>
                  <p class="pb-6">
                    （2）如果我们收集、使用您的个人信息，却未征得您的同意；
                  </p>
                  <p class="pb-6">
                    （3）如果我们处理个人信息的行为违反了与您的约定；
                  </p>
                  <p class="pb-6">
                    （4）如果你不再使用我们的产品或服务，或您注销了账号；
                  </p>
                  <p class="pb-6">（5）如果我们不再为您提供产品或服务。</p>
                  <p class="pb-6">
                    2、若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。
                  </p>
                  <p class="pb-6">
                    3、当您从我们的服务中删除信息后，受限于系统运营我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。
                  </p>
                  <p class="pb-6">（四）改变授权同意的范围或撤回授权</p>
                  <p class="pb-6">
                    1、您可以通过删除信息，进行隐私权限设置等方式改变您继续授权我们收集个人信息的范围或撤回您的授权。您也可以通过注销账户的方式，撤回我们继续收集您个人信息的全部授权；
                  </p>
                  <p class="pb-6">
                    2、请您理解，撤回同意或授权后，我们将无法为您提供与之相对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而展开的个人信息处理。
                  </p>
                  <p class="pb-6">（五）响应您的上述请求</p>
                  <p class="pb-6">
                    1、为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
                  </p>
                  <p class="pb-6">
                    2、我们将在15个工作日内做出答复。若该期限内因为客观原因的限制，确实无法给出答复的，我们将提前联系您延长答复期限，希望给到您满意的答复。
                  </p>
                  <p class="pb-6">3、在以下情形中，我们将无法响应您的请求：</p>
                  <p class="pb-6">
                    （1）与个人信息控制者履行法律法规规定的义务相关的；
                  </p>
                  <p class="pb-6">（2）与国家安全、国防安全直接相关的；</p>
                  <p class="pb-6">
                    （3）与公共安全、公共卫生、重大公共利益直接相关的；
                  </p>
                  <p class="pb-6">
                    （4）与犯罪侦查、起诉、审判和执行判决等直接相关的；
                  </p>
                  <p class="pb-6">
                    （5）个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的；
                  </p>
                  <p class="pb-6">
                    （6）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
                  </p>
                  <p class="pb-6">
                    （7）响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的
                  </p>
                  <p class="pb-6">（8）涉及商业秘密的。</p>
                  <p class="pb-6">
                    在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害本网站商业秘密或其他用户权益、社会公共利益的前提下提供适当的救济方式。
                  </p>
                  <p class="pb-6">六、未成年人保护</p>
                  <p class="pb-6">
                    我们的产品、网站和服务主要面向成年人，我们不建议未成年人使用我们的产品/服务。如您为未成年人或未成年人的监护人，发现未成年人正在使用我们的服务或向我们提供信息的，本网站将立即冻结相关账号，并有权取消相关交易。
                  </p>
                  <p class="pb-6">七、如何联系我们</p>
                  <p class="pb-6">
                    如您有关于网络信息安全的任何投近和举报，或您对本《隐私政策》、您的信息相关事宜有任何问题、意见或建议，以及有关本《隐私政策》或
                    SurpSkin 的隐私措施的问题，请与我们联系：
                  </p>
                  <p class="pb-6">
                    您可以在网页任一界面右侧找到悬浮挂件，点击“客服”后，方可与我们取得联系！
                  </p>
                  <p class="pb-6">我们会给予您必要的帮助！</p>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { appStateStore } from "@/store/app.store";

const appStore = appStateStore();

function closeModal() {
  appStore.updateIsPrivacyPolicyModel(false)
}
</script>
