import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { useUserStore } from "./store/index";
import router from "./router/index";

router.beforeEach(async (to, from, next) => {
  const result = checkLoginState(to, next);
  // 代表没有特殊处理
  if (result === true) {
    next();
  }
});

/**
 * 检查页面是否需要登录
 * @param to 目标路由
 * @result 返回true则正常跳转
 */
function checkLoginState(
  to: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const userStore = useUserStore();
  if (userStore.isLogin) {
    return true;
  } else if (to.meta.need_login === true) {
    next({
      path: "/",
      query: { back_url: to.fullPath },
    });
  } else {
    return true;
  }
  return false;
}
