<template>
  <!-- 充值入口 -->
  <TransitionRoot appear :show="isRechargeModel" as="template">
    <Dialog as="div" @close="closeModel" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/85" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-[677px] transform overflow-hidden rounded-2xl bg-[#23232D] shadow-[0_10px_40px_#1d2021] p-6 text-left align-middle transition-all"
            >
              <div
                @click="closeModel"
                class="absolute top-4 right-4 closeHover"
              >
                <iconpark-icon
                  color="#ffffff"
                  size="20"
                  name="guanbiclose"
                ></iconpark-icon>
              </div>
              <!-- 支付宝支付 -->
              <!-- <AlipayPayment @closeModel="closeModel"></AlipayPayment> -->
              <!-- 易支付支付 -->
              <!-- <EasyPayment></EasyPayment> -->
              <!-- 微信支付 -->
              <WechatPayment @closeModel="closeModel"></WechatPayment>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script lang="ts" setup>
import { appStateStore } from "@/store/app.store";
// import AlipayPayment from "@/views/user/deposit/alipayPayment.vue";
// import EasyPayment from "@/views/user/deposit/easyPayment.vue";
import WechatPayment from "@/views/user/deposit/wechatPayment.vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
} from "@headlessui/vue";
import { ref, watch } from "vue";

const appStore = appStateStore();
const isRechargeModel = ref(false);

watch(
  () => [appStore.isRechargeModel], // 修正这里的逗号
  () => {
    isRechargeModel.value = appStore.isRechargeModel;
  },
  {
    deep: true,
  }
);

const closeModel = () => {
  isRechargeModel.value = false;
  appStore.updateIsRechargeModel(false);
};
</script>
