import { defineStore } from "pinia";
import { clearCookie, getCookie } from "@/utils/cookie";
import {
  UserCenter,
  getUserInfo,
  getUserInventoryTotal,
} from "@/api/user/info";
import { logout } from "@/api/login";
import { getConfig, getOnlineUsers } from "@/api/config";
import { saveTask } from "@/api/user/freeCoin";

interface Online {
  countBox: string;
  countOnlinePeople: string;
  countPeople: string;
}

// cookie内用于保存token的key
export const tokenCookieKey = "token";

// 更新读取用户token的方法
function updateLoginState() {
  return getCookie(tokenCookieKey);
}

export const useUserStore = defineStore({
  id: "user",
  state: () => {
    return {
      token: updateLoginState(),
      userMsg: undefined,
      config: undefined,
      inventoryTotal: "0.00",
      onlineDataStatistics: {
        countBox: "0",
        countOnlinePeople: "0",
        countPeople: "0",
      },
    } as {
      token?: string;
      userMsg?: UserCenter.UserAccountCont;
      config?: any;
      inventoryTotal?: string;
      onlineDataStatistics: Online; // 实时在线人数
    };
  },
  getters: {
    // 是否登录
    isLogin: (state) => state.token && state.token.length > 0,
    // 用户头像
    avatar: (state) => state.userMsg?.userInfo?.avatar,
    // 用户昵称
    nickName: (state) => state.userMsg?.userInfo?.nickName ?? "",
    // 交易链接
    steamTradeURL: (state) => state.userMsg?.userInfo?.steamTradeURL ?? "",
    // 邀请码
    promotionCode: (state) => state.userMsg?.userInfo?.promotionCode,
    // userId
    userId: (state) => state.userMsg?.userInfo?.userId,
    // 身份证
    idCard: (state) => state.userMsg?.userInfo?.idCard,
    // 最小充值
    minRecharge: (state) => state.config?.minAOM ?? 0,
    // 最大充值
    maxRecharge: (state) => state.config?.maxAOM ?? 0,
    // 充值套餐
    rechargePackage: (state) => state.config?.valueSavingPackage ?? [],
    // 充值赠送
    rechargeGive: (state) => state.config?.valueSavingGift ?? [],
    // 推广等级
    promotionLevel: (state) => state.config?.promotionLevel ?? [],
    // 汇率差
    exchangeRate: (state) => state.config?.inventoryRatio ?? 0,
    // 开箱门槛
    openBoxThreshold: (state) => state.config?.unlockThreshold ?? 0,
    // 公告文案
    announcement: (state) => state.config?.announcementCopy ?? "",
    // 余额
    balance: (state) => {
      const balance = state.userMsg?.userAccount?.balance;
      return balance !== undefined && balance !== null
        ? (+balance).toFixed(2)
        : "0.00";
    },
    // 库存总价值
    inventoryTotalValue: (state) => {
      const inventoryTotal = state.inventoryTotal;
      return inventoryTotal !== undefined && inventoryTotal !== null
        ? (+inventoryTotal).toFixed(2)
        : "0.00";
    },
    // 在线人数
    numberOfOnlineUsers: (state) =>
      state.onlineDataStatistics.countOnlinePeople,
    // 总用户数
    totalNumberOfUsers: (state) => state.onlineDataStatistics.countPeople,
    // 开启宝箱总数
    totalNumberOfOpenedChests: (state) => state.onlineDataStatistics.countBox,
  },
  actions: {
    /// 登出
    async logout() {
      this.token = updateLoginState();
      if (this.token && this.token.length > 0) {
        try {
          const requestData = await logout();
          if (requestData) {
            /// 清理从id登陆的cookie
            clearCookie(tokenCookieKey);
            window.location.href = window.location.origin;
          }
        } catch {
          /// 清理从id登陆的cookie
          clearCookie(tokenCookieKey);
          window.location.href = window.location.origin;
        }
      }
    },

    /**
     * 设置Token
     * @param token 用户标识
     */
    configToken() {
      this.token = updateLoginState();
    },

    /// 更新用户信息
    async updateUserMsg() {
      this.token = updateLoginState();
      if (this.token && this.token.length > 0) {
        const requestData = await getUserInfo();
        if (requestData) {
          const { data } = requestData;
          this.saveUserMsg(data);
        }
      }
    },

    /// 获取用户库存总价值
    async getUserInventoryValue() {
      this.token = updateLoginState();
      if (this.token && this.token.length > 0) {
        const requestData = await getUserInventoryTotal();
        if (requestData) {
          const { data } = requestData;
          this.inventoryTotal = (data as string) ?? "0.00";
        }
      }
    },

    /// 保存用户信息
    async saveUserMsg(msg: any | undefined) {
      if (this.userMsg !== msg) {
        this.userMsg = msg;
      }
    },

    /// 获取全局配置
    async updateGlobalConfig() {
      // this.token = updateLoginState();
      // if (this.token && this.token.length > 0) {
        const requestData = await getConfig();
        if (requestData) {
          const { data } = requestData;
          this.config = data;
        }
      // }
    },

    async getTheGlobalOnlinePopulation() {
      const requestData = await getOnlineUsers();
      if (requestData) {
        const { data } = requestData;
        this.onlineDataStatistics = data as any;
      }
    },

    // 给用户分配任务
    async getSaveTask() {
      try {
        await saveTask();
      } catch (error) {
        console.log(error);
      }
    },
  },
});
