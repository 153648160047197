const battlePage = () => import("@/views/battle/index.vue");
const battleIndexPage = () => import("@/views/battle/battleIndex/index.vue");

// 对战首页 - 主路由
export const BattlePagePath = "/battle";
export const BattlePageName = "BattlePage";

// 对战列表
export const BattleIndexPagePath = "/battle/index";
export const BattleIndexPageName = "BattleIndexPage";

// 创建对战
export const BattleCreatePagePath = "/battle/create";
export const BattleCreatePageName = "BattleCreatePage";

// 对战详情
export const BattleOpenChestPagePath = "/battle/battleOpenChest";
export const BattleOpenChestPageName = "BattleOpenChestPage";

const routes = [
  {
    path: BattlePagePath,
    name: BattlePageName,
    component: battlePage,
    meta: {
      titleKey: "GlobalSupplierOfSteamAccessories",
    },
    redirect: { name: BattleIndexPagePath },
    children: [
      // 对战列表
      {
        path: BattleIndexPagePath,
        name: BattleIndexPageName,
        component: battleIndexPage,
      },
      // 创建对战
      {
        path: BattleCreatePagePath,
        name: BattleCreatePageName,
        component: () => import("@/views/battle/battleCreate/index.vue"),
      },
      // 对战详情
      {
        path: BattleOpenChestPagePath,
        name: BattleOpenChestPageName,
        component: () => import("@/views/battle/battleOpenChest/index.vue"),
      }
    ],
  }
];

export default routes;
