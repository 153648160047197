import axios from "./request";

// 获取支付宝支付二维码
export const paymentAlipayQrCode = (parmas: { money: string }) => {
  // 返回的数据格式可以和服务端约定
  return axios.get<any>(
    `/api/skins_client/pay`,
    { money: parmas.money },
    { responseType: "blob" }
  );
};

// 监听支付宝支付状态
export const paymentAlipayStatus = () => {
  return axios.get<any>(`/api/skins_client/pay/getStatus`);
};

// 获取微信支付二维码
export const paymentWxQrCode = (parmas: {
  money: string;
}) => {
  // 返回的数据格式可以和服务端约定
  return axios.get<any>(
    `/api/skins_client/weixin/pay`,
    { money: parmas.money },
    { responseType: "blob" }
  );
};

// 监听微信支付状态
export const paymentWxStatus = () => {
  return axios.get<any>(`/api/skins_client/pay/getStatus`);
};

// 易支付
export const paymentYipay = (parmas: {
  type: string;
  returnUrl: string;
  name: string;
  money: string;
}) => {
  return axios.post<any>(`/api/skins_client/easyPayment/pay`, parmas);
};
