import { defineStore } from "pinia";
// import { clearRequest, socket, startRequest } from "@/socket";
import { clearRequest, socket } from "@/socket";

export const useSocketStore = defineStore({
  id: "socket",
  state: () => {
    return {
      linkIdentification: '',
      allRealTimeDropData: [],
      bastRealTimeDropData: [],
      allRealtimeDrops: [],
      bestRealtimeDrops: [],
    } as {
      linkIdentification: string; // 链接标识
      allRealTimeDropData: any[]; // 全部掉落
      bastRealTimeDropData: any[]; // 最佳掉落
      allRealtimeDrops: any[]; // 实时数据最佳掉落
      bestRealtimeDrops: any[]; // 最佳数据最佳掉落
    };
  },
  getters: {
    // 全部掉落数据
    linkIdentificationData: (state) => state.linkIdentification,
    // 全部掉落数据
    allDropData: (state) => state.allRealTimeDropData,
    // 最佳全部掉落数据
    bestDropData: (state) => state.bastRealTimeDropData,
    // 全部实时掉落
    allRealtimeDropsData: (state) => state.allRealtimeDrops,
    // 最佳实时掉落
    bestRealtimeDropsData: (state) => state.bestRealtimeDrops,
  },
  actions: {
    // // 测试发送
    sendMessage(message: string) {
      socket.send(message);
    },

    // 关闭链接
    close() {
      socket.close();
    },

    // 清除轮训
    clearInterval() {
      clearRequest();
    },

    // 开始轮训
    setInterval() {
      // startRequest(this.linkIdentificationData)
    },

    // 掉落
    appendAllRealTimeDrop(item: any) {
      item.forEach((ele: any) => {
        Object.assign(ele, { itemInfo: JSON.parse(ele.itemInfo) });
      });
      this.allRealTimeDropData = item;
    },

    // 最佳
    appendBestRealTimeDrop(item: any) {
      item.forEach((ele: any) => {
        Object.assign(ele, { itemInfo: JSON.parse(ele.itemInfo) });
      });
      this.bastRealTimeDropData = item;
    },

    // 全部实时掉落
    appendLatestDropItems(item: any) {
      item.forEach((ele: any) => {
        Object.assign(ele, { itemInfo: JSON.parse(ele.itemInfo) });
      });
      this.allRealtimeDrops = item;
    },

    // 最佳实时掉落
    appendBestDropItems(item: any) {
      item.forEach((ele: any) => {
        Object.assign(ele, { itemInfo: JSON.parse(ele.itemInfo) });
      });
      this.bestRealtimeDrops = item;
    },

    // 用户链接唯一标识
    appendLinkIdentification(item: string) {
      this.linkIdentification = item;
    },
  },
});
