export default {
  // 密码
  password: /^(?![^a-zA-Z]+$)(?!\D+$).{8,16}$/,
  // 邮箱
  emailRegex: /^.*@.*$/,
  // 手机号
  mobile: /^1[3-9]\d{9}$/,
  // 验证码
  code: /^\d{6}$/,
  // 身份证
  idCard: /(^\d{15}$)|(^\d{17}([0-9]|X|x)$)/,
  // 姓名
  name: /^([\u4e00-\u9fa5]{1,20}|[a-zA-Z\.\s]{1,20})$/,
  // 邀请码
  inviteCode: /^[a-zA-Z0-9]{6}$/,
}
